const tokens = {
  "borders.none": {
    "value": "none",
    "variable": "var(--borders-none)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-default)"
  },
  "easings.linear": {
    "value": "linear",
    "variable": "var(--easings-linear)"
  },
  "easings.in": {
    "value": "cubic-bezier(0.4, 0, 1, 1)",
    "variable": "var(--easings-in)"
  },
  "easings.out": {
    "value": "cubic-bezier(0, 0, 0.2, 1)",
    "variable": "var(--easings-out)"
  },
  "easings.in-out": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-in-out)"
  },
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--durations-slowest)"
  },
  "fontWeights.thin": {
    "value": "100",
    "variable": "var(--font-weights-thin)"
  },
  "fontWeights.extralight": {
    "value": "200",
    "variable": "var(--font-weights-extralight)"
  },
  "fontWeights.light": {
    "value": "300",
    "variable": "var(--font-weights-light)"
  },
  "fontWeights.normal": {
    "value": "400",
    "variable": "var(--font-weights-normal)"
  },
  "fontWeights.medium": {
    "value": "500",
    "variable": "var(--font-weights-medium)"
  },
  "fontWeights.semibold": {
    "value": "600",
    "variable": "var(--font-weights-semibold)"
  },
  "fontWeights.bold": {
    "value": "700",
    "variable": "var(--font-weights-bold)"
  },
  "fontWeights.extrabold": {
    "value": "800",
    "variable": "var(--font-weights-extrabold)"
  },
  "fontWeights.black": {
    "value": "900",
    "variable": "var(--font-weights-black)"
  },
  "lineHeights.none": {
    "value": "1",
    "variable": "var(--line-heights-none)"
  },
  "lineHeights.tight": {
    "value": "1.25",
    "variable": "var(--line-heights-tight)"
  },
  "lineHeights.snug": {
    "value": "1.375",
    "variable": "var(--line-heights-snug)"
  },
  "lineHeights.normal": {
    "value": "1.5",
    "variable": "var(--line-heights-normal)"
  },
  "lineHeights.relaxed": {
    "value": "1.625",
    "variable": "var(--line-heights-relaxed)"
  },
  "lineHeights.loose": {
    "value": "2",
    "variable": "var(--line-heights-loose)"
  },
  "fonts.sans": {
    "value": "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
    "variable": "var(--fonts-sans)"
  },
  "fonts.serif": {
    "value": "ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif",
    "variable": "var(--fonts-serif)"
  },
  "fonts.mono": {
    "value": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
    "variable": "var(--fonts-mono)"
  },
  "letterSpacings.tighter": {
    "value": "-0.05em",
    "variable": "var(--letter-spacings-tighter)"
  },
  "letterSpacings.tight": {
    "value": "-0.025em",
    "variable": "var(--letter-spacings-tight)"
  },
  "letterSpacings.normal": {
    "value": "0em",
    "variable": "var(--letter-spacings-normal)"
  },
  "letterSpacings.wide": {
    "value": "0.025em",
    "variable": "var(--letter-spacings-wide)"
  },
  "letterSpacings.wider": {
    "value": "0.05em",
    "variable": "var(--letter-spacings-wider)"
  },
  "letterSpacings.widest": {
    "value": "0.1em",
    "variable": "var(--letter-spacings-widest)"
  },
  "fontSizes.2xs": {
    "value": "0.5rem",
    "variable": "var(--font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "1.875rem",
    "variable": "var(--font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.25rem",
    "variable": "var(--font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--font-sizes-5xl)"
  },
  "fontSizes.6xl": {
    "value": "3.75rem",
    "variable": "var(--font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--font-sizes-7xl)"
  },
  "fontSizes.8xl": {
    "value": "6rem",
    "variable": "var(--font-sizes-8xl)"
  },
  "fontSizes.9xl": {
    "value": "8rem",
    "variable": "var(--font-sizes-9xl)"
  },
  "shadows.xs": {
    "value": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-xs)"
  },
  "shadows.sm": {
    "value": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-sm)"
  },
  "shadows.md": {
    "value": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-md)"
  },
  "shadows.lg": {
    "value": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-lg)"
  },
  "shadows.xl": {
    "value": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-xl)"
  },
  "shadows.2xl": {
    "value": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
    "variable": "var(--shadows-2xl)"
  },
  "shadows.inner": {
    "value": "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-inner)"
  },
  "colors.current": {
    "value": "currentColor",
    "variable": "var(--colors-current)"
  },
  "colors.black": {
    "value": "#000",
    "variable": "var(--colors-black)"
  },
  "colors.white": {
    "value": "#fff",
    "variable": "var(--colors-white)"
  },
  "colors.transparent": {
    "value": "rgb(0 0 0 / 0)",
    "variable": "var(--colors-transparent)"
  },
  "colors.rose.50": {
    "value": "#fff1f2",
    "variable": "var(--colors-rose-50)"
  },
  "colors.rose.100": {
    "value": "#ffe4e6",
    "variable": "var(--colors-rose-100)"
  },
  "colors.rose.200": {
    "value": "#fecdd3",
    "variable": "var(--colors-rose-200)"
  },
  "colors.rose.300": {
    "value": "#fda4af",
    "variable": "var(--colors-rose-300)"
  },
  "colors.rose.400": {
    "value": "#fb7185",
    "variable": "var(--colors-rose-400)"
  },
  "colors.rose.500": {
    "value": "#f43f5e",
    "variable": "var(--colors-rose-500)"
  },
  "colors.rose.600": {
    "value": "#e11d48",
    "variable": "var(--colors-rose-600)"
  },
  "colors.rose.700": {
    "value": "#be123c",
    "variable": "var(--colors-rose-700)"
  },
  "colors.rose.800": {
    "value": "#9f1239",
    "variable": "var(--colors-rose-800)"
  },
  "colors.rose.900": {
    "value": "#881337",
    "variable": "var(--colors-rose-900)"
  },
  "colors.rose.950": {
    "value": "#4c0519",
    "variable": "var(--colors-rose-950)"
  },
  "colors.pink.50": {
    "value": "#fdf2f8",
    "variable": "var(--colors-pink-50)"
  },
  "colors.pink.100": {
    "value": "#fce7f3",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#fbcfe8",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#f9a8d4",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#f472b6",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#ec4899",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#db2777",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#be185d",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#9d174d",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#831843",
    "variable": "var(--colors-pink-900)"
  },
  "colors.pink.950": {
    "value": "#500724",
    "variable": "var(--colors-pink-950)"
  },
  "colors.fuchsia.50": {
    "value": "#fdf4ff",
    "variable": "var(--colors-fuchsia-50)"
  },
  "colors.fuchsia.100": {
    "value": "#fae8ff",
    "variable": "var(--colors-fuchsia-100)"
  },
  "colors.fuchsia.200": {
    "value": "#f5d0fe",
    "variable": "var(--colors-fuchsia-200)"
  },
  "colors.fuchsia.300": {
    "value": "#f0abfc",
    "variable": "var(--colors-fuchsia-300)"
  },
  "colors.fuchsia.400": {
    "value": "#e879f9",
    "variable": "var(--colors-fuchsia-400)"
  },
  "colors.fuchsia.500": {
    "value": "#d946ef",
    "variable": "var(--colors-fuchsia-500)"
  },
  "colors.fuchsia.600": {
    "value": "#c026d3",
    "variable": "var(--colors-fuchsia-600)"
  },
  "colors.fuchsia.700": {
    "value": "#a21caf",
    "variable": "var(--colors-fuchsia-700)"
  },
  "colors.fuchsia.800": {
    "value": "#86198f",
    "variable": "var(--colors-fuchsia-800)"
  },
  "colors.fuchsia.900": {
    "value": "#701a75",
    "variable": "var(--colors-fuchsia-900)"
  },
  "colors.fuchsia.950": {
    "value": "#4a044e",
    "variable": "var(--colors-fuchsia-950)"
  },
  "colors.purple.50": {
    "value": "#faf5ff",
    "variable": "var(--colors-purple-50)"
  },
  "colors.purple.100": {
    "value": "#f3e8ff",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#e9d5ff",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#d8b4fe",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#c084fc",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#a855f7",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#9333ea",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#7e22ce",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#6b21a8",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#581c87",
    "variable": "var(--colors-purple-900)"
  },
  "colors.purple.950": {
    "value": "#3b0764",
    "variable": "var(--colors-purple-950)"
  },
  "colors.violet.50": {
    "value": "#f5f3ff",
    "variable": "var(--colors-violet-50)"
  },
  "colors.violet.100": {
    "value": "#ede9fe",
    "variable": "var(--colors-violet-100)"
  },
  "colors.violet.200": {
    "value": "#ddd6fe",
    "variable": "var(--colors-violet-200)"
  },
  "colors.violet.300": {
    "value": "#c4b5fd",
    "variable": "var(--colors-violet-300)"
  },
  "colors.violet.400": {
    "value": "#a78bfa",
    "variable": "var(--colors-violet-400)"
  },
  "colors.violet.500": {
    "value": "#8b5cf6",
    "variable": "var(--colors-violet-500)"
  },
  "colors.violet.600": {
    "value": "#7c3aed",
    "variable": "var(--colors-violet-600)"
  },
  "colors.violet.700": {
    "value": "#6d28d9",
    "variable": "var(--colors-violet-700)"
  },
  "colors.violet.800": {
    "value": "#5b21b6",
    "variable": "var(--colors-violet-800)"
  },
  "colors.violet.900": {
    "value": "#4c1d95",
    "variable": "var(--colors-violet-900)"
  },
  "colors.violet.950": {
    "value": "#2e1065",
    "variable": "var(--colors-violet-950)"
  },
  "colors.indigo.50": {
    "value": "#eef2ff",
    "variable": "var(--colors-indigo-50)"
  },
  "colors.indigo.100": {
    "value": "#e0e7ff",
    "variable": "var(--colors-indigo-100)"
  },
  "colors.indigo.200": {
    "value": "#c7d2fe",
    "variable": "var(--colors-indigo-200)"
  },
  "colors.indigo.300": {
    "value": "#a5b4fc",
    "variable": "var(--colors-indigo-300)"
  },
  "colors.indigo.400": {
    "value": "#818cf8",
    "variable": "var(--colors-indigo-400)"
  },
  "colors.indigo.500": {
    "value": "#6366f1",
    "variable": "var(--colors-indigo-500)"
  },
  "colors.indigo.600": {
    "value": "#4f46e5",
    "variable": "var(--colors-indigo-600)"
  },
  "colors.indigo.700": {
    "value": "#4338ca",
    "variable": "var(--colors-indigo-700)"
  },
  "colors.indigo.800": {
    "value": "#3730a3",
    "variable": "var(--colors-indigo-800)"
  },
  "colors.indigo.900": {
    "value": "#312e81",
    "variable": "var(--colors-indigo-900)"
  },
  "colors.indigo.950": {
    "value": "#1e1b4b",
    "variable": "var(--colors-indigo-950)"
  },
  "colors.blue.50": {
    "value": "#eff6ff",
    "variable": "var(--colors-blue-50)"
  },
  "colors.blue.100": {
    "value": "#dbeafe",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#bfdbfe",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#93c5fd",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#60a5fa",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#3b82f6",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#2563eb",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#1d4ed8",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#1e40af",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#1e3a8a",
    "variable": "var(--colors-blue-900)"
  },
  "colors.blue.950": {
    "value": "#172554",
    "variable": "var(--colors-blue-950)"
  },
  "colors.sky.50": {
    "value": "#f0f9ff",
    "variable": "var(--colors-sky-50)"
  },
  "colors.sky.100": {
    "value": "#e0f2fe",
    "variable": "var(--colors-sky-100)"
  },
  "colors.sky.200": {
    "value": "#bae6fd",
    "variable": "var(--colors-sky-200)"
  },
  "colors.sky.300": {
    "value": "#7dd3fc",
    "variable": "var(--colors-sky-300)"
  },
  "colors.sky.400": {
    "value": "#38bdf8",
    "variable": "var(--colors-sky-400)"
  },
  "colors.sky.500": {
    "value": "#0ea5e9",
    "variable": "var(--colors-sky-500)"
  },
  "colors.sky.600": {
    "value": "#0284c7",
    "variable": "var(--colors-sky-600)"
  },
  "colors.sky.700": {
    "value": "#0369a1",
    "variable": "var(--colors-sky-700)"
  },
  "colors.sky.800": {
    "value": "#075985",
    "variable": "var(--colors-sky-800)"
  },
  "colors.sky.900": {
    "value": "#0c4a6e",
    "variable": "var(--colors-sky-900)"
  },
  "colors.sky.950": {
    "value": "#082f49",
    "variable": "var(--colors-sky-950)"
  },
  "colors.cyan.50": {
    "value": "#ecfeff",
    "variable": "var(--colors-cyan-50)"
  },
  "colors.cyan.100": {
    "value": "#cffafe",
    "variable": "var(--colors-cyan-100)"
  },
  "colors.cyan.200": {
    "value": "#a5f3fc",
    "variable": "var(--colors-cyan-200)"
  },
  "colors.cyan.300": {
    "value": "#67e8f9",
    "variable": "var(--colors-cyan-300)"
  },
  "colors.cyan.400": {
    "value": "#22d3ee",
    "variable": "var(--colors-cyan-400)"
  },
  "colors.cyan.500": {
    "value": "#06b6d4",
    "variable": "var(--colors-cyan-500)"
  },
  "colors.cyan.600": {
    "value": "#0891b2",
    "variable": "var(--colors-cyan-600)"
  },
  "colors.cyan.700": {
    "value": "#0e7490",
    "variable": "var(--colors-cyan-700)"
  },
  "colors.cyan.800": {
    "value": "#155e75",
    "variable": "var(--colors-cyan-800)"
  },
  "colors.cyan.900": {
    "value": "#164e63",
    "variable": "var(--colors-cyan-900)"
  },
  "colors.cyan.950": {
    "value": "#083344",
    "variable": "var(--colors-cyan-950)"
  },
  "colors.teal.50": {
    "value": "#f0fdfa",
    "variable": "var(--colors-teal-50)"
  },
  "colors.teal.100": {
    "value": "#ccfbf1",
    "variable": "var(--colors-teal-100)"
  },
  "colors.teal.200": {
    "value": "#99f6e4",
    "variable": "var(--colors-teal-200)"
  },
  "colors.teal.300": {
    "value": "#5eead4",
    "variable": "var(--colors-teal-300)"
  },
  "colors.teal.400": {
    "value": "#2dd4bf",
    "variable": "var(--colors-teal-400)"
  },
  "colors.teal.500": {
    "value": "#14b8a6",
    "variable": "var(--colors-teal-500)"
  },
  "colors.teal.600": {
    "value": "#0d9488",
    "variable": "var(--colors-teal-600)"
  },
  "colors.teal.700": {
    "value": "#0f766e",
    "variable": "var(--colors-teal-700)"
  },
  "colors.teal.800": {
    "value": "#115e59",
    "variable": "var(--colors-teal-800)"
  },
  "colors.teal.900": {
    "value": "#134e4a",
    "variable": "var(--colors-teal-900)"
  },
  "colors.teal.950": {
    "value": "#042f2e",
    "variable": "var(--colors-teal-950)"
  },
  "colors.emerald.50": {
    "value": "#ecfdf5",
    "variable": "var(--colors-emerald-50)"
  },
  "colors.emerald.100": {
    "value": "#d1fae5",
    "variable": "var(--colors-emerald-100)"
  },
  "colors.emerald.200": {
    "value": "#a7f3d0",
    "variable": "var(--colors-emerald-200)"
  },
  "colors.emerald.300": {
    "value": "#6ee7b7",
    "variable": "var(--colors-emerald-300)"
  },
  "colors.emerald.400": {
    "value": "#34d399",
    "variable": "var(--colors-emerald-400)"
  },
  "colors.emerald.500": {
    "value": "#10b981",
    "variable": "var(--colors-emerald-500)"
  },
  "colors.emerald.600": {
    "value": "#059669",
    "variable": "var(--colors-emerald-600)"
  },
  "colors.emerald.700": {
    "value": "#047857",
    "variable": "var(--colors-emerald-700)"
  },
  "colors.emerald.800": {
    "value": "#065f46",
    "variable": "var(--colors-emerald-800)"
  },
  "colors.emerald.900": {
    "value": "#064e3b",
    "variable": "var(--colors-emerald-900)"
  },
  "colors.emerald.950": {
    "value": "#022c22",
    "variable": "var(--colors-emerald-950)"
  },
  "colors.green.50": {
    "value": "#f0fdf4",
    "variable": "var(--colors-green-50)"
  },
  "colors.green.100": {
    "value": "#dcfce7",
    "variable": "var(--colors-green-100)"
  },
  "colors.green.200": {
    "value": "#bbf7d0",
    "variable": "var(--colors-green-200)"
  },
  "colors.green.300": {
    "value": "#86efac",
    "variable": "var(--colors-green-300)"
  },
  "colors.green.400": {
    "value": "#4ade80",
    "variable": "var(--colors-green-400)"
  },
  "colors.green.500": {
    "value": "#22c55e",
    "variable": "var(--colors-green-500)"
  },
  "colors.green.600": {
    "value": "#16a34a",
    "variable": "var(--colors-green-600)"
  },
  "colors.green.700": {
    "value": "#15803d",
    "variable": "var(--colors-green-700)"
  },
  "colors.green.800": {
    "value": "#166534",
    "variable": "var(--colors-green-800)"
  },
  "colors.green.900": {
    "value": "#14532d",
    "variable": "var(--colors-green-900)"
  },
  "colors.green.950": {
    "value": "#052e16",
    "variable": "var(--colors-green-950)"
  },
  "colors.lime.50": {
    "value": "#f7fee7",
    "variable": "var(--colors-lime-50)"
  },
  "colors.lime.100": {
    "value": "#ecfccb",
    "variable": "var(--colors-lime-100)"
  },
  "colors.lime.200": {
    "value": "#d9f99d",
    "variable": "var(--colors-lime-200)"
  },
  "colors.lime.300": {
    "value": "#bef264",
    "variable": "var(--colors-lime-300)"
  },
  "colors.lime.400": {
    "value": "#a3e635",
    "variable": "var(--colors-lime-400)"
  },
  "colors.lime.500": {
    "value": "#84cc16",
    "variable": "var(--colors-lime-500)"
  },
  "colors.lime.600": {
    "value": "#65a30d",
    "variable": "var(--colors-lime-600)"
  },
  "colors.lime.700": {
    "value": "#4d7c0f",
    "variable": "var(--colors-lime-700)"
  },
  "colors.lime.800": {
    "value": "#3f6212",
    "variable": "var(--colors-lime-800)"
  },
  "colors.lime.900": {
    "value": "#365314",
    "variable": "var(--colors-lime-900)"
  },
  "colors.lime.950": {
    "value": "#1a2e05",
    "variable": "var(--colors-lime-950)"
  },
  "colors.yellow.50": {
    "value": "#fefce8",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#fef9c3",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#fef08a",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#fde047",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#facc15",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#eab308",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#ca8a04",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#a16207",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#854d0e",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#713f12",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.yellow.950": {
    "value": "#422006",
    "variable": "var(--colors-yellow-950)"
  },
  "colors.amber.50": {
    "value": "#fffbeb",
    "variable": "var(--colors-amber-50)"
  },
  "colors.amber.100": {
    "value": "#fef3c7",
    "variable": "var(--colors-amber-100)"
  },
  "colors.amber.200": {
    "value": "#fde68a",
    "variable": "var(--colors-amber-200)"
  },
  "colors.amber.300": {
    "value": "#fcd34d",
    "variable": "var(--colors-amber-300)"
  },
  "colors.amber.400": {
    "value": "#fbbf24",
    "variable": "var(--colors-amber-400)"
  },
  "colors.amber.500": {
    "value": "#f59e0b",
    "variable": "var(--colors-amber-500)"
  },
  "colors.amber.600": {
    "value": "#d97706",
    "variable": "var(--colors-amber-600)"
  },
  "colors.amber.700": {
    "value": "#b45309",
    "variable": "var(--colors-amber-700)"
  },
  "colors.amber.800": {
    "value": "#92400e",
    "variable": "var(--colors-amber-800)"
  },
  "colors.amber.900": {
    "value": "#78350f",
    "variable": "var(--colors-amber-900)"
  },
  "colors.amber.950": {
    "value": "#451a03",
    "variable": "var(--colors-amber-950)"
  },
  "colors.orange.50": {
    "value": "#fff7ed",
    "variable": "var(--colors-orange-50)"
  },
  "colors.orange.100": {
    "value": "#ffedd5",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#fed7aa",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#fdba74",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#fb923c",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#f97316",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#ea580c",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#c2410c",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#9a3412",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#7c2d12",
    "variable": "var(--colors-orange-900)"
  },
  "colors.orange.950": {
    "value": "#431407",
    "variable": "var(--colors-orange-950)"
  },
  "colors.red.50": {
    "value": "#fef2f2",
    "variable": "var(--colors-red-50)"
  },
  "colors.red.100": {
    "value": "#fee2e2",
    "variable": "var(--colors-red-100)"
  },
  "colors.red.200": {
    "value": "#fecaca",
    "variable": "var(--colors-red-200)"
  },
  "colors.red.300": {
    "value": "#fca5a5",
    "variable": "var(--colors-red-300)"
  },
  "colors.red.400": {
    "value": "#f87171",
    "variable": "var(--colors-red-400)"
  },
  "colors.red.500": {
    "value": "#ef4444",
    "variable": "var(--colors-red-500)"
  },
  "colors.red.600": {
    "value": "#dc2626",
    "variable": "var(--colors-red-600)"
  },
  "colors.red.700": {
    "value": "#b91c1c",
    "variable": "var(--colors-red-700)"
  },
  "colors.red.800": {
    "value": "#991b1b",
    "variable": "var(--colors-red-800)"
  },
  "colors.red.900": {
    "value": "#7f1d1d",
    "variable": "var(--colors-red-900)"
  },
  "colors.red.950": {
    "value": "#450a0a",
    "variable": "var(--colors-red-950)"
  },
  "colors.neutral.50": {
    "value": "#fafafa",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.100": {
    "value": "#f5f5f5",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#e5e5e5",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#d4d4d4",
    "variable": "var(--colors-neutral-300)"
  },
  "colors.neutral.400": {
    "value": "#a3a3a3",
    "variable": "var(--colors-neutral-400)"
  },
  "colors.neutral.500": {
    "value": "#737373",
    "variable": "var(--colors-neutral-500)"
  },
  "colors.neutral.600": {
    "value": "#525252",
    "variable": "var(--colors-neutral-600)"
  },
  "colors.neutral.700": {
    "value": "#404040",
    "variable": "var(--colors-neutral-700)"
  },
  "colors.neutral.800": {
    "value": "#262626",
    "variable": "var(--colors-neutral-800)"
  },
  "colors.neutral.900": {
    "value": "#171717",
    "variable": "var(--colors-neutral-900)"
  },
  "colors.neutral.950": {
    "value": "#0a0a0a",
    "variable": "var(--colors-neutral-950)"
  },
  "colors.stone.50": {
    "value": "#fafaf9",
    "variable": "var(--colors-stone-50)"
  },
  "colors.stone.100": {
    "value": "#f5f5f4",
    "variable": "var(--colors-stone-100)"
  },
  "colors.stone.200": {
    "value": "#e7e5e4",
    "variable": "var(--colors-stone-200)"
  },
  "colors.stone.300": {
    "value": "#d6d3d1",
    "variable": "var(--colors-stone-300)"
  },
  "colors.stone.400": {
    "value": "#a8a29e",
    "variable": "var(--colors-stone-400)"
  },
  "colors.stone.500": {
    "value": "#78716c",
    "variable": "var(--colors-stone-500)"
  },
  "colors.stone.600": {
    "value": "#57534e",
    "variable": "var(--colors-stone-600)"
  },
  "colors.stone.700": {
    "value": "#44403c",
    "variable": "var(--colors-stone-700)"
  },
  "colors.stone.800": {
    "value": "#292524",
    "variable": "var(--colors-stone-800)"
  },
  "colors.stone.900": {
    "value": "#1c1917",
    "variable": "var(--colors-stone-900)"
  },
  "colors.stone.950": {
    "value": "#0c0a09",
    "variable": "var(--colors-stone-950)"
  },
  "colors.zinc.50": {
    "value": "#fafafa",
    "variable": "var(--colors-zinc-50)"
  },
  "colors.zinc.100": {
    "value": "#f4f4f5",
    "variable": "var(--colors-zinc-100)"
  },
  "colors.zinc.200": {
    "value": "#e4e4e7",
    "variable": "var(--colors-zinc-200)"
  },
  "colors.zinc.300": {
    "value": "#d4d4d8",
    "variable": "var(--colors-zinc-300)"
  },
  "colors.zinc.400": {
    "value": "#a1a1aa",
    "variable": "var(--colors-zinc-400)"
  },
  "colors.zinc.500": {
    "value": "#71717a",
    "variable": "var(--colors-zinc-500)"
  },
  "colors.zinc.600": {
    "value": "#52525b",
    "variable": "var(--colors-zinc-600)"
  },
  "colors.zinc.700": {
    "value": "#3f3f46",
    "variable": "var(--colors-zinc-700)"
  },
  "colors.zinc.800": {
    "value": "#27272a",
    "variable": "var(--colors-zinc-800)"
  },
  "colors.zinc.900": {
    "value": "#18181b",
    "variable": "var(--colors-zinc-900)"
  },
  "colors.zinc.950": {
    "value": "#09090b",
    "variable": "var(--colors-zinc-950)"
  },
  "colors.gray.50": {
    "value": "#f9fafb",
    "variable": "var(--colors-gray-50)"
  },
  "colors.gray.100": {
    "value": "#f3f4f6",
    "variable": "var(--colors-gray-100)"
  },
  "colors.gray.200": {
    "value": "#e5e7eb",
    "variable": "var(--colors-gray-200)"
  },
  "colors.gray.300": {
    "value": "#d1d5db",
    "variable": "var(--colors-gray-300)"
  },
  "colors.gray.400": {
    "value": "#9ca3af",
    "variable": "var(--colors-gray-400)"
  },
  "colors.gray.500": {
    "value": "#6b7280",
    "variable": "var(--colors-gray-500)"
  },
  "colors.gray.600": {
    "value": "#4b5563",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#374151",
    "variable": "var(--colors-gray-700)"
  },
  "colors.gray.800": {
    "value": "#1f2937",
    "variable": "var(--colors-gray-800)"
  },
  "colors.gray.900": {
    "value": "#111827",
    "variable": "var(--colors-gray-900)"
  },
  "colors.gray.950": {
    "value": "#030712",
    "variable": "var(--colors-gray-950)"
  },
  "colors.slate.50": {
    "value": "#f8fafc",
    "variable": "var(--colors-slate-50)"
  },
  "colors.slate.100": {
    "value": "#f1f5f9",
    "variable": "var(--colors-slate-100)"
  },
  "colors.slate.200": {
    "value": "#e2e8f0",
    "variable": "var(--colors-slate-200)"
  },
  "colors.slate.300": {
    "value": "#cbd5e1",
    "variable": "var(--colors-slate-300)"
  },
  "colors.slate.400": {
    "value": "#94a3b8",
    "variable": "var(--colors-slate-400)"
  },
  "colors.slate.500": {
    "value": "#64748b",
    "variable": "var(--colors-slate-500)"
  },
  "colors.slate.600": {
    "value": "#475569",
    "variable": "var(--colors-slate-600)"
  },
  "colors.slate.700": {
    "value": "#334155",
    "variable": "var(--colors-slate-700)"
  },
  "colors.slate.800": {
    "value": "#1e293b",
    "variable": "var(--colors-slate-800)"
  },
  "colors.slate.900": {
    "value": "#0f172a",
    "variable": "var(--colors-slate-900)"
  },
  "colors.slate.950": {
    "value": "#020617",
    "variable": "var(--colors-slate-950)"
  },
  "blurs.sm": {
    "value": "4px",
    "variable": "var(--blurs-sm)"
  },
  "blurs.base": {
    "value": "8px",
    "variable": "var(--blurs-base)"
  },
  "blurs.md": {
    "value": "12px",
    "variable": "var(--blurs-md)"
  },
  "blurs.lg": {
    "value": "16px",
    "variable": "var(--blurs-lg)"
  },
  "blurs.xl": {
    "value": "24px",
    "variable": "var(--blurs-xl)"
  },
  "blurs.2xl": {
    "value": "40px",
    "variable": "var(--blurs-2xl)"
  },
  "blurs.3xl": {
    "value": "64px",
    "variable": "var(--blurs-3xl)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "0.25rem",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "0.5rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.3": {
    "value": "0.75rem",
    "variable": "var(--spacing-3)"
  },
  "spacing.4": {
    "value": "1rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.5": {
    "value": "1.25rem",
    "variable": "var(--spacing-5)"
  },
  "spacing.6": {
    "value": "1.5rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.7": {
    "value": "1.75rem",
    "variable": "var(--spacing-7)"
  },
  "spacing.8": {
    "value": "2rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.9": {
    "value": "2.25rem",
    "variable": "var(--spacing-9)"
  },
  "spacing.10": {
    "value": "2.5rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.11": {
    "value": "2.75rem",
    "variable": "var(--spacing-11)"
  },
  "spacing.12": {
    "value": "3rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "3.5rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "4rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "5rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "6rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "7rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "8rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "9rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "10rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "11rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "12rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.52": {
    "value": "13rem",
    "variable": "var(--spacing-52)"
  },
  "spacing.56": {
    "value": "14rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.60": {
    "value": "15rem",
    "variable": "var(--spacing-60)"
  },
  "spacing.64": {
    "value": "16rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.72": {
    "value": "18rem",
    "variable": "var(--spacing-72)"
  },
  "spacing.80": {
    "value": "20rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "24rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.0.5": {
    "value": "0.125rem",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.1.5": {
    "value": "0.375rem",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.2.5": {
    "value": "0.625rem",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.3.5": {
    "value": "0.875rem",
    "variable": "var(--spacing-3\\.5)"
  },
  "sizes.0": {
    "value": "0rem",
    "variable": "var(--sizes-0)"
  },
  "sizes.1": {
    "value": "0.25rem",
    "variable": "var(--sizes-1)"
  },
  "sizes.2": {
    "value": "0.5rem",
    "variable": "var(--sizes-2)"
  },
  "sizes.3": {
    "value": "0.75rem",
    "variable": "var(--sizes-3)"
  },
  "sizes.4": {
    "value": "1rem",
    "variable": "var(--sizes-4)"
  },
  "sizes.5": {
    "value": "1.25rem",
    "variable": "var(--sizes-5)"
  },
  "sizes.6": {
    "value": "1.5rem",
    "variable": "var(--sizes-6)"
  },
  "sizes.7": {
    "value": "1.75rem",
    "variable": "var(--sizes-7)"
  },
  "sizes.8": {
    "value": "2rem",
    "variable": "var(--sizes-8)"
  },
  "sizes.9": {
    "value": "2.25rem",
    "variable": "var(--sizes-9)"
  },
  "sizes.10": {
    "value": "2.5rem",
    "variable": "var(--sizes-10)"
  },
  "sizes.11": {
    "value": "2.75rem",
    "variable": "var(--sizes-11)"
  },
  "sizes.12": {
    "value": "3rem",
    "variable": "var(--sizes-12)"
  },
  "sizes.14": {
    "value": "3.5rem",
    "variable": "var(--sizes-14)"
  },
  "sizes.16": {
    "value": "4rem",
    "variable": "var(--sizes-16)"
  },
  "sizes.20": {
    "value": "5rem",
    "variable": "var(--sizes-20)"
  },
  "sizes.24": {
    "value": "6rem",
    "variable": "var(--sizes-24)"
  },
  "sizes.28": {
    "value": "7rem",
    "variable": "var(--sizes-28)"
  },
  "sizes.32": {
    "value": "8rem",
    "variable": "var(--sizes-32)"
  },
  "sizes.36": {
    "value": "9rem",
    "variable": "var(--sizes-36)"
  },
  "sizes.40": {
    "value": "10rem",
    "variable": "var(--sizes-40)"
  },
  "sizes.44": {
    "value": "11rem",
    "variable": "var(--sizes-44)"
  },
  "sizes.48": {
    "value": "12rem",
    "variable": "var(--sizes-48)"
  },
  "sizes.52": {
    "value": "13rem",
    "variable": "var(--sizes-52)"
  },
  "sizes.56": {
    "value": "14rem",
    "variable": "var(--sizes-56)"
  },
  "sizes.60": {
    "value": "15rem",
    "variable": "var(--sizes-60)"
  },
  "sizes.64": {
    "value": "16rem",
    "variable": "var(--sizes-64)"
  },
  "sizes.72": {
    "value": "18rem",
    "variable": "var(--sizes-72)"
  },
  "sizes.80": {
    "value": "20rem",
    "variable": "var(--sizes-80)"
  },
  "sizes.96": {
    "value": "24rem",
    "variable": "var(--sizes-96)"
  },
  "sizes.0.5": {
    "value": "0.125rem",
    "variable": "var(--sizes-0\\.5)"
  },
  "sizes.1.5": {
    "value": "0.375rem",
    "variable": "var(--sizes-1\\.5)"
  },
  "sizes.2.5": {
    "value": "0.625rem",
    "variable": "var(--sizes-2\\.5)"
  },
  "sizes.3.5": {
    "value": "0.875rem",
    "variable": "var(--sizes-3\\.5)"
  },
  "sizes.xs": {
    "value": "20rem",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "24rem",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "28rem",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "32rem",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "36rem",
    "variable": "var(--sizes-xl)"
  },
  "sizes.2xl": {
    "value": "42rem",
    "variable": "var(--sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "48rem",
    "variable": "var(--sizes-3xl)"
  },
  "sizes.4xl": {
    "value": "56rem",
    "variable": "var(--sizes-4xl)"
  },
  "sizes.5xl": {
    "value": "64rem",
    "variable": "var(--sizes-5xl)"
  },
  "sizes.6xl": {
    "value": "72rem",
    "variable": "var(--sizes-6xl)"
  },
  "sizes.7xl": {
    "value": "80rem",
    "variable": "var(--sizes-7xl)"
  },
  "sizes.8xl": {
    "value": "90rem",
    "variable": "var(--sizes-8xl)"
  },
  "sizes.prose": {
    "value": "65ch",
    "variable": "var(--sizes-prose)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--sizes-full)"
  },
  "sizes.min": {
    "value": "min-content",
    "variable": "var(--sizes-min)"
  },
  "sizes.max": {
    "value": "max-content",
    "variable": "var(--sizes-max)"
  },
  "sizes.fit": {
    "value": "fit-content",
    "variable": "var(--sizes-fit)"
  },
  "sizes.breakpoint-sm": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "animations.spin": {
    "value": "spin 1s linear infinite",
    "variable": "var(--animations-spin)"
  },
  "animations.ping": {
    "value": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    "variable": "var(--animations-ping)"
  },
  "animations.pulse": {
    "value": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    "variable": "var(--animations-pulse)"
  },
  "animations.bounce": {
    "value": "bounce 1s infinite",
    "variable": "var(--animations-bounce)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "1rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "1.75rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "3rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "4rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.3xl": {
    "value": "6.25rem",
    "variable": "var(--radii-3xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "zIndex.hide": {
    "value": -1,
    "variable": "var(--z-index-hide)"
  },
  "zIndex.tooltip": {
    "value": 100,
    "variable": "var(--z-index-tooltip)"
  },
  "zIndex.modal": {
    "value": 100,
    "variable": "var(--z-index-modal)"
  },
  "zIndex.menu": {
    "value": 100,
    "variable": "var(--z-index-menu)"
  },
  "zIndex.overlay": {
    "value": 300,
    "variable": "var(--z-index-overlay)"
  },
  "zIndex.overlayContent": {
    "value": 301,
    "variable": "var(--z-index-overlay-content)"
  },
  "breakpoints.sm": {
    "value": "640px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--breakpoints-2xl)"
  },
  "colors.m3.primary": {
    "value": "var(--colors-m3-primary)",
    "variable": "var(--colors-m3-primary)"
  },
  "colors.m3.primary008": {
    "value": "var(--colors-m3-primary008)",
    "variable": "var(--colors-m3-primary008)"
  },
  "colors.m3.primary012": {
    "value": "var(--colors-m3-primary012)",
    "variable": "var(--colors-m3-primary012)"
  },
  "colors.m3.primary038": {
    "value": "var(--colors-m3-primary038)",
    "variable": "var(--colors-m3-primary038)"
  },
  "colors.m3.primary090": {
    "value": "var(--colors-m3-primary090)",
    "variable": "var(--colors-m3-primary090)"
  },
  "colors.m3.onPrimary": {
    "value": "var(--colors-m3-on-primary)",
    "variable": "var(--colors-m3-on-primary)"
  },
  "colors.m3.onPrimary008": {
    "value": "var(--colors-m3-on-primary008)",
    "variable": "var(--colors-m3-on-primary008)"
  },
  "colors.m3.onPrimary012": {
    "value": "var(--colors-m3-on-primary012)",
    "variable": "var(--colors-m3-on-primary012)"
  },
  "colors.m3.onPrimary038": {
    "value": "var(--colors-m3-on-primary038)",
    "variable": "var(--colors-m3-on-primary038)"
  },
  "colors.m3.onPrimary090": {
    "value": "var(--colors-m3-on-primary090)",
    "variable": "var(--colors-m3-on-primary090)"
  },
  "colors.m3.primaryContainer": {
    "value": "var(--colors-m3-primary-container)",
    "variable": "var(--colors-m3-primary-container)"
  },
  "colors.m3.primaryContainer008": {
    "value": "var(--colors-m3-primary-container008)",
    "variable": "var(--colors-m3-primary-container008)"
  },
  "colors.m3.primaryContainer012": {
    "value": "var(--colors-m3-primary-container012)",
    "variable": "var(--colors-m3-primary-container012)"
  },
  "colors.m3.primaryContainer038": {
    "value": "var(--colors-m3-primary-container038)",
    "variable": "var(--colors-m3-primary-container038)"
  },
  "colors.m3.primaryContainer090": {
    "value": "var(--colors-m3-primary-container090)",
    "variable": "var(--colors-m3-primary-container090)"
  },
  "colors.m3.onPrimaryContainer": {
    "value": "var(--colors-m3-on-primary-container)",
    "variable": "var(--colors-m3-on-primary-container)"
  },
  "colors.m3.onPrimaryContainer008": {
    "value": "var(--colors-m3-on-primary-container008)",
    "variable": "var(--colors-m3-on-primary-container008)"
  },
  "colors.m3.onPrimaryContainer012": {
    "value": "var(--colors-m3-on-primary-container012)",
    "variable": "var(--colors-m3-on-primary-container012)"
  },
  "colors.m3.onPrimaryContainer038": {
    "value": "var(--colors-m3-on-primary-container038)",
    "variable": "var(--colors-m3-on-primary-container038)"
  },
  "colors.m3.onPrimaryContainer090": {
    "value": "var(--colors-m3-on-primary-container090)",
    "variable": "var(--colors-m3-on-primary-container090)"
  },
  "colors.m3.primaryFixed": {
    "value": "var(--colors-m3-primary-fixed)",
    "variable": "var(--colors-m3-primary-fixed)"
  },
  "colors.m3.primaryFixed008": {
    "value": "var(--colors-m3-primary-fixed008)",
    "variable": "var(--colors-m3-primary-fixed008)"
  },
  "colors.m3.primaryFixed012": {
    "value": "var(--colors-m3-primary-fixed012)",
    "variable": "var(--colors-m3-primary-fixed012)"
  },
  "colors.m3.primaryFixed038": {
    "value": "var(--colors-m3-primary-fixed038)",
    "variable": "var(--colors-m3-primary-fixed038)"
  },
  "colors.m3.primaryFixed090": {
    "value": "var(--colors-m3-primary-fixed090)",
    "variable": "var(--colors-m3-primary-fixed090)"
  },
  "colors.m3.onPrimaryFixed": {
    "value": "var(--colors-m3-on-primary-fixed)",
    "variable": "var(--colors-m3-on-primary-fixed)"
  },
  "colors.m3.onPrimaryFixed008": {
    "value": "var(--colors-m3-on-primary-fixed008)",
    "variable": "var(--colors-m3-on-primary-fixed008)"
  },
  "colors.m3.onPrimaryFixed012": {
    "value": "var(--colors-m3-on-primary-fixed012)",
    "variable": "var(--colors-m3-on-primary-fixed012)"
  },
  "colors.m3.onPrimaryFixed038": {
    "value": "var(--colors-m3-on-primary-fixed038)",
    "variable": "var(--colors-m3-on-primary-fixed038)"
  },
  "colors.m3.onPrimaryFixed090": {
    "value": "var(--colors-m3-on-primary-fixed090)",
    "variable": "var(--colors-m3-on-primary-fixed090)"
  },
  "colors.m3.primaryFixedDim": {
    "value": "var(--colors-m3-primary-fixed-dim)",
    "variable": "var(--colors-m3-primary-fixed-dim)"
  },
  "colors.m3.primaryFixedDim008": {
    "value": "var(--colors-m3-primary-fixed-dim008)",
    "variable": "var(--colors-m3-primary-fixed-dim008)"
  },
  "colors.m3.primaryFixedDim012": {
    "value": "var(--colors-m3-primary-fixed-dim012)",
    "variable": "var(--colors-m3-primary-fixed-dim012)"
  },
  "colors.m3.primaryFixedDim038": {
    "value": "var(--colors-m3-primary-fixed-dim038)",
    "variable": "var(--colors-m3-primary-fixed-dim038)"
  },
  "colors.m3.primaryFixedDim090": {
    "value": "var(--colors-m3-primary-fixed-dim090)",
    "variable": "var(--colors-m3-primary-fixed-dim090)"
  },
  "colors.m3.onPrimaryFixedVariant": {
    "value": "var(--colors-m3-on-primary-fixed-variant)",
    "variable": "var(--colors-m3-on-primary-fixed-variant)"
  },
  "colors.m3.onPrimaryFixedVariant008": {
    "value": "var(--colors-m3-on-primary-fixed-variant008)",
    "variable": "var(--colors-m3-on-primary-fixed-variant008)"
  },
  "colors.m3.onPrimaryFixedVariant012": {
    "value": "var(--colors-m3-on-primary-fixed-variant012)",
    "variable": "var(--colors-m3-on-primary-fixed-variant012)"
  },
  "colors.m3.onPrimaryFixedVariant038": {
    "value": "var(--colors-m3-on-primary-fixed-variant038)",
    "variable": "var(--colors-m3-on-primary-fixed-variant038)"
  },
  "colors.m3.onPrimaryFixedVariant090": {
    "value": "var(--colors-m3-on-primary-fixed-variant090)",
    "variable": "var(--colors-m3-on-primary-fixed-variant090)"
  },
  "colors.m3.secondary": {
    "value": "var(--colors-m3-secondary)",
    "variable": "var(--colors-m3-secondary)"
  },
  "colors.m3.secondary008": {
    "value": "var(--colors-m3-secondary008)",
    "variable": "var(--colors-m3-secondary008)"
  },
  "colors.m3.secondary012": {
    "value": "var(--colors-m3-secondary012)",
    "variable": "var(--colors-m3-secondary012)"
  },
  "colors.m3.secondary038": {
    "value": "var(--colors-m3-secondary038)",
    "variable": "var(--colors-m3-secondary038)"
  },
  "colors.m3.secondary090": {
    "value": "var(--colors-m3-secondary090)",
    "variable": "var(--colors-m3-secondary090)"
  },
  "colors.m3.onSecondary": {
    "value": "var(--colors-m3-on-secondary)",
    "variable": "var(--colors-m3-on-secondary)"
  },
  "colors.m3.onSecondary008": {
    "value": "var(--colors-m3-on-secondary008)",
    "variable": "var(--colors-m3-on-secondary008)"
  },
  "colors.m3.onSecondary012": {
    "value": "var(--colors-m3-on-secondary012)",
    "variable": "var(--colors-m3-on-secondary012)"
  },
  "colors.m3.onSecondary038": {
    "value": "var(--colors-m3-on-secondary038)",
    "variable": "var(--colors-m3-on-secondary038)"
  },
  "colors.m3.onSecondary090": {
    "value": "var(--colors-m3-on-secondary090)",
    "variable": "var(--colors-m3-on-secondary090)"
  },
  "colors.m3.secondaryContainer": {
    "value": "var(--colors-m3-secondary-container)",
    "variable": "var(--colors-m3-secondary-container)"
  },
  "colors.m3.secondaryContainer008": {
    "value": "var(--colors-m3-secondary-container008)",
    "variable": "var(--colors-m3-secondary-container008)"
  },
  "colors.m3.secondaryContainer012": {
    "value": "var(--colors-m3-secondary-container012)",
    "variable": "var(--colors-m3-secondary-container012)"
  },
  "colors.m3.secondaryContainer038": {
    "value": "var(--colors-m3-secondary-container038)",
    "variable": "var(--colors-m3-secondary-container038)"
  },
  "colors.m3.secondaryContainer090": {
    "value": "var(--colors-m3-secondary-container090)",
    "variable": "var(--colors-m3-secondary-container090)"
  },
  "colors.m3.onSecondaryContainer": {
    "value": "var(--colors-m3-on-secondary-container)",
    "variable": "var(--colors-m3-on-secondary-container)"
  },
  "colors.m3.onSecondaryContainer008": {
    "value": "var(--colors-m3-on-secondary-container008)",
    "variable": "var(--colors-m3-on-secondary-container008)"
  },
  "colors.m3.onSecondaryContainer012": {
    "value": "var(--colors-m3-on-secondary-container012)",
    "variable": "var(--colors-m3-on-secondary-container012)"
  },
  "colors.m3.onSecondaryContainer038": {
    "value": "var(--colors-m3-on-secondary-container038)",
    "variable": "var(--colors-m3-on-secondary-container038)"
  },
  "colors.m3.onSecondaryContainer090": {
    "value": "var(--colors-m3-on-secondary-container090)",
    "variable": "var(--colors-m3-on-secondary-container090)"
  },
  "colors.m3.secondaryFixed": {
    "value": "var(--colors-m3-secondary-fixed)",
    "variable": "var(--colors-m3-secondary-fixed)"
  },
  "colors.m3.secondaryFixed008": {
    "value": "var(--colors-m3-secondary-fixed008)",
    "variable": "var(--colors-m3-secondary-fixed008)"
  },
  "colors.m3.secondaryFixed012": {
    "value": "var(--colors-m3-secondary-fixed012)",
    "variable": "var(--colors-m3-secondary-fixed012)"
  },
  "colors.m3.secondaryFixed038": {
    "value": "var(--colors-m3-secondary-fixed038)",
    "variable": "var(--colors-m3-secondary-fixed038)"
  },
  "colors.m3.secondaryFixed090": {
    "value": "var(--colors-m3-secondary-fixed090)",
    "variable": "var(--colors-m3-secondary-fixed090)"
  },
  "colors.m3.onSecondaryFixed": {
    "value": "var(--colors-m3-on-secondary-fixed)",
    "variable": "var(--colors-m3-on-secondary-fixed)"
  },
  "colors.m3.onSecondaryFixed008": {
    "value": "var(--colors-m3-on-secondary-fixed008)",
    "variable": "var(--colors-m3-on-secondary-fixed008)"
  },
  "colors.m3.onSecondaryFixed012": {
    "value": "var(--colors-m3-on-secondary-fixed012)",
    "variable": "var(--colors-m3-on-secondary-fixed012)"
  },
  "colors.m3.onSecondaryFixed038": {
    "value": "var(--colors-m3-on-secondary-fixed038)",
    "variable": "var(--colors-m3-on-secondary-fixed038)"
  },
  "colors.m3.onSecondaryFixed090": {
    "value": "var(--colors-m3-on-secondary-fixed090)",
    "variable": "var(--colors-m3-on-secondary-fixed090)"
  },
  "colors.m3.secondaryFixedDim": {
    "value": "var(--colors-m3-secondary-fixed-dim)",
    "variable": "var(--colors-m3-secondary-fixed-dim)"
  },
  "colors.m3.secondaryFixedDim008": {
    "value": "var(--colors-m3-secondary-fixed-dim008)",
    "variable": "var(--colors-m3-secondary-fixed-dim008)"
  },
  "colors.m3.secondaryFixedDim012": {
    "value": "var(--colors-m3-secondary-fixed-dim012)",
    "variable": "var(--colors-m3-secondary-fixed-dim012)"
  },
  "colors.m3.secondaryFixedDim038": {
    "value": "var(--colors-m3-secondary-fixed-dim038)",
    "variable": "var(--colors-m3-secondary-fixed-dim038)"
  },
  "colors.m3.secondaryFixedDim090": {
    "value": "var(--colors-m3-secondary-fixed-dim090)",
    "variable": "var(--colors-m3-secondary-fixed-dim090)"
  },
  "colors.m3.onSecondaryFixedVariant": {
    "value": "var(--colors-m3-on-secondary-fixed-variant)",
    "variable": "var(--colors-m3-on-secondary-fixed-variant)"
  },
  "colors.m3.onSecondaryFixedVariant008": {
    "value": "var(--colors-m3-on-secondary-fixed-variant008)",
    "variable": "var(--colors-m3-on-secondary-fixed-variant008)"
  },
  "colors.m3.onSecondaryFixedVariant012": {
    "value": "var(--colors-m3-on-secondary-fixed-variant012)",
    "variable": "var(--colors-m3-on-secondary-fixed-variant012)"
  },
  "colors.m3.onSecondaryFixedVariant038": {
    "value": "var(--colors-m3-on-secondary-fixed-variant038)",
    "variable": "var(--colors-m3-on-secondary-fixed-variant038)"
  },
  "colors.m3.onSecondaryFixedVariant090": {
    "value": "var(--colors-m3-on-secondary-fixed-variant090)",
    "variable": "var(--colors-m3-on-secondary-fixed-variant090)"
  },
  "colors.m3.tertiary": {
    "value": "var(--colors-m3-tertiary)",
    "variable": "var(--colors-m3-tertiary)"
  },
  "colors.m3.tertiary008": {
    "value": "var(--colors-m3-tertiary008)",
    "variable": "var(--colors-m3-tertiary008)"
  },
  "colors.m3.tertiary012": {
    "value": "var(--colors-m3-tertiary012)",
    "variable": "var(--colors-m3-tertiary012)"
  },
  "colors.m3.tertiary038": {
    "value": "var(--colors-m3-tertiary038)",
    "variable": "var(--colors-m3-tertiary038)"
  },
  "colors.m3.tertiary090": {
    "value": "var(--colors-m3-tertiary090)",
    "variable": "var(--colors-m3-tertiary090)"
  },
  "colors.m3.onTertiary": {
    "value": "var(--colors-m3-on-tertiary)",
    "variable": "var(--colors-m3-on-tertiary)"
  },
  "colors.m3.onTertiary008": {
    "value": "var(--colors-m3-on-tertiary008)",
    "variable": "var(--colors-m3-on-tertiary008)"
  },
  "colors.m3.onTertiary012": {
    "value": "var(--colors-m3-on-tertiary012)",
    "variable": "var(--colors-m3-on-tertiary012)"
  },
  "colors.m3.onTertiary038": {
    "value": "var(--colors-m3-on-tertiary038)",
    "variable": "var(--colors-m3-on-tertiary038)"
  },
  "colors.m3.onTertiary090": {
    "value": "var(--colors-m3-on-tertiary090)",
    "variable": "var(--colors-m3-on-tertiary090)"
  },
  "colors.m3.tertiaryContainer": {
    "value": "var(--colors-m3-tertiary-container)",
    "variable": "var(--colors-m3-tertiary-container)"
  },
  "colors.m3.tertiaryContainer008": {
    "value": "var(--colors-m3-tertiary-container008)",
    "variable": "var(--colors-m3-tertiary-container008)"
  },
  "colors.m3.tertiaryContainer012": {
    "value": "var(--colors-m3-tertiary-container012)",
    "variable": "var(--colors-m3-tertiary-container012)"
  },
  "colors.m3.tertiaryContainer038": {
    "value": "var(--colors-m3-tertiary-container038)",
    "variable": "var(--colors-m3-tertiary-container038)"
  },
  "colors.m3.tertiaryContainer090": {
    "value": "var(--colors-m3-tertiary-container090)",
    "variable": "var(--colors-m3-tertiary-container090)"
  },
  "colors.m3.onTertiaryContainer": {
    "value": "var(--colors-m3-on-tertiary-container)",
    "variable": "var(--colors-m3-on-tertiary-container)"
  },
  "colors.m3.onTertiaryContainer008": {
    "value": "var(--colors-m3-on-tertiary-container008)",
    "variable": "var(--colors-m3-on-tertiary-container008)"
  },
  "colors.m3.onTertiaryContainer012": {
    "value": "var(--colors-m3-on-tertiary-container012)",
    "variable": "var(--colors-m3-on-tertiary-container012)"
  },
  "colors.m3.onTertiaryContainer038": {
    "value": "var(--colors-m3-on-tertiary-container038)",
    "variable": "var(--colors-m3-on-tertiary-container038)"
  },
  "colors.m3.onTertiaryContainer090": {
    "value": "var(--colors-m3-on-tertiary-container090)",
    "variable": "var(--colors-m3-on-tertiary-container090)"
  },
  "colors.m3.tertiaryFixed": {
    "value": "var(--colors-m3-tertiary-fixed)",
    "variable": "var(--colors-m3-tertiary-fixed)"
  },
  "colors.m3.tertiaryFixed008": {
    "value": "var(--colors-m3-tertiary-fixed008)",
    "variable": "var(--colors-m3-tertiary-fixed008)"
  },
  "colors.m3.tertiaryFixed012": {
    "value": "var(--colors-m3-tertiary-fixed012)",
    "variable": "var(--colors-m3-tertiary-fixed012)"
  },
  "colors.m3.tertiaryFixed038": {
    "value": "var(--colors-m3-tertiary-fixed038)",
    "variable": "var(--colors-m3-tertiary-fixed038)"
  },
  "colors.m3.tertiaryFixed090": {
    "value": "var(--colors-m3-tertiary-fixed090)",
    "variable": "var(--colors-m3-tertiary-fixed090)"
  },
  "colors.m3.onTertiaryFixed": {
    "value": "var(--colors-m3-on-tertiary-fixed)",
    "variable": "var(--colors-m3-on-tertiary-fixed)"
  },
  "colors.m3.onTertiaryFixed008": {
    "value": "var(--colors-m3-on-tertiary-fixed008)",
    "variable": "var(--colors-m3-on-tertiary-fixed008)"
  },
  "colors.m3.onTertiaryFixed012": {
    "value": "var(--colors-m3-on-tertiary-fixed012)",
    "variable": "var(--colors-m3-on-tertiary-fixed012)"
  },
  "colors.m3.onTertiaryFixed038": {
    "value": "var(--colors-m3-on-tertiary-fixed038)",
    "variable": "var(--colors-m3-on-tertiary-fixed038)"
  },
  "colors.m3.onTertiaryFixed090": {
    "value": "var(--colors-m3-on-tertiary-fixed090)",
    "variable": "var(--colors-m3-on-tertiary-fixed090)"
  },
  "colors.m3.tertiaryFixedDim": {
    "value": "var(--colors-m3-tertiary-fixed-dim)",
    "variable": "var(--colors-m3-tertiary-fixed-dim)"
  },
  "colors.m3.tertiaryFixedDim008": {
    "value": "var(--colors-m3-tertiary-fixed-dim008)",
    "variable": "var(--colors-m3-tertiary-fixed-dim008)"
  },
  "colors.m3.tertiaryFixedDim012": {
    "value": "var(--colors-m3-tertiary-fixed-dim012)",
    "variable": "var(--colors-m3-tertiary-fixed-dim012)"
  },
  "colors.m3.tertiaryFixedDim038": {
    "value": "var(--colors-m3-tertiary-fixed-dim038)",
    "variable": "var(--colors-m3-tertiary-fixed-dim038)"
  },
  "colors.m3.tertiaryFixedDim090": {
    "value": "var(--colors-m3-tertiary-fixed-dim090)",
    "variable": "var(--colors-m3-tertiary-fixed-dim090)"
  },
  "colors.m3.onTertiaryFixedVariant": {
    "value": "var(--colors-m3-on-tertiary-fixed-variant)",
    "variable": "var(--colors-m3-on-tertiary-fixed-variant)"
  },
  "colors.m3.onTertiaryFixedVariant008": {
    "value": "var(--colors-m3-on-tertiary-fixed-variant008)",
    "variable": "var(--colors-m3-on-tertiary-fixed-variant008)"
  },
  "colors.m3.onTertiaryFixedVariant012": {
    "value": "var(--colors-m3-on-tertiary-fixed-variant012)",
    "variable": "var(--colors-m3-on-tertiary-fixed-variant012)"
  },
  "colors.m3.onTertiaryFixedVariant038": {
    "value": "var(--colors-m3-on-tertiary-fixed-variant038)",
    "variable": "var(--colors-m3-on-tertiary-fixed-variant038)"
  },
  "colors.m3.onTertiaryFixedVariant090": {
    "value": "var(--colors-m3-on-tertiary-fixed-variant090)",
    "variable": "var(--colors-m3-on-tertiary-fixed-variant090)"
  },
  "colors.m3.error": {
    "value": "var(--colors-m3-error)",
    "variable": "var(--colors-m3-error)"
  },
  "colors.m3.error008": {
    "value": "var(--colors-m3-error008)",
    "variable": "var(--colors-m3-error008)"
  },
  "colors.m3.error012": {
    "value": "var(--colors-m3-error012)",
    "variable": "var(--colors-m3-error012)"
  },
  "colors.m3.error038": {
    "value": "var(--colors-m3-error038)",
    "variable": "var(--colors-m3-error038)"
  },
  "colors.m3.error090": {
    "value": "var(--colors-m3-error090)",
    "variable": "var(--colors-m3-error090)"
  },
  "colors.m3.onError": {
    "value": "var(--colors-m3-on-error)",
    "variable": "var(--colors-m3-on-error)"
  },
  "colors.m3.onError008": {
    "value": "var(--colors-m3-on-error008)",
    "variable": "var(--colors-m3-on-error008)"
  },
  "colors.m3.onError012": {
    "value": "var(--colors-m3-on-error012)",
    "variable": "var(--colors-m3-on-error012)"
  },
  "colors.m3.onError038": {
    "value": "var(--colors-m3-on-error038)",
    "variable": "var(--colors-m3-on-error038)"
  },
  "colors.m3.onError090": {
    "value": "var(--colors-m3-on-error090)",
    "variable": "var(--colors-m3-on-error090)"
  },
  "colors.m3.errorContainer": {
    "value": "var(--colors-m3-error-container)",
    "variable": "var(--colors-m3-error-container)"
  },
  "colors.m3.errorContainer008": {
    "value": "var(--colors-m3-error-container008)",
    "variable": "var(--colors-m3-error-container008)"
  },
  "colors.m3.errorContainer012": {
    "value": "var(--colors-m3-error-container012)",
    "variable": "var(--colors-m3-error-container012)"
  },
  "colors.m3.errorContainer038": {
    "value": "var(--colors-m3-error-container038)",
    "variable": "var(--colors-m3-error-container038)"
  },
  "colors.m3.errorContainer090": {
    "value": "var(--colors-m3-error-container090)",
    "variable": "var(--colors-m3-error-container090)"
  },
  "colors.m3.onErrorContainer": {
    "value": "var(--colors-m3-on-error-container)",
    "variable": "var(--colors-m3-on-error-container)"
  },
  "colors.m3.onErrorContainer008": {
    "value": "var(--colors-m3-on-error-container008)",
    "variable": "var(--colors-m3-on-error-container008)"
  },
  "colors.m3.onErrorContainer012": {
    "value": "var(--colors-m3-on-error-container012)",
    "variable": "var(--colors-m3-on-error-container012)"
  },
  "colors.m3.onErrorContainer038": {
    "value": "var(--colors-m3-on-error-container038)",
    "variable": "var(--colors-m3-on-error-container038)"
  },
  "colors.m3.onErrorContainer090": {
    "value": "var(--colors-m3-on-error-container090)",
    "variable": "var(--colors-m3-on-error-container090)"
  },
  "colors.m3.outline": {
    "value": "var(--colors-m3-outline)",
    "variable": "var(--colors-m3-outline)"
  },
  "colors.m3.outline008": {
    "value": "var(--colors-m3-outline008)",
    "variable": "var(--colors-m3-outline008)"
  },
  "colors.m3.outline012": {
    "value": "var(--colors-m3-outline012)",
    "variable": "var(--colors-m3-outline012)"
  },
  "colors.m3.outline038": {
    "value": "var(--colors-m3-outline038)",
    "variable": "var(--colors-m3-outline038)"
  },
  "colors.m3.outline090": {
    "value": "var(--colors-m3-outline090)",
    "variable": "var(--colors-m3-outline090)"
  },
  "colors.m3.background": {
    "value": "var(--colors-m3-background)",
    "variable": "var(--colors-m3-background)"
  },
  "colors.m3.background008": {
    "value": "var(--colors-m3-background008)",
    "variable": "var(--colors-m3-background008)"
  },
  "colors.m3.background012": {
    "value": "var(--colors-m3-background012)",
    "variable": "var(--colors-m3-background012)"
  },
  "colors.m3.background038": {
    "value": "var(--colors-m3-background038)",
    "variable": "var(--colors-m3-background038)"
  },
  "colors.m3.background090": {
    "value": "var(--colors-m3-background090)",
    "variable": "var(--colors-m3-background090)"
  },
  "colors.m3.onBackground": {
    "value": "var(--colors-m3-on-background)",
    "variable": "var(--colors-m3-on-background)"
  },
  "colors.m3.onBackground008": {
    "value": "var(--colors-m3-on-background008)",
    "variable": "var(--colors-m3-on-background008)"
  },
  "colors.m3.onBackground012": {
    "value": "var(--colors-m3-on-background012)",
    "variable": "var(--colors-m3-on-background012)"
  },
  "colors.m3.onBackground038": {
    "value": "var(--colors-m3-on-background038)",
    "variable": "var(--colors-m3-on-background038)"
  },
  "colors.m3.onBackground090": {
    "value": "var(--colors-m3-on-background090)",
    "variable": "var(--colors-m3-on-background090)"
  },
  "colors.m3.surface": {
    "value": "var(--colors-m3-surface)",
    "variable": "var(--colors-m3-surface)"
  },
  "colors.m3.surface008": {
    "value": "var(--colors-m3-surface008)",
    "variable": "var(--colors-m3-surface008)"
  },
  "colors.m3.surface012": {
    "value": "var(--colors-m3-surface012)",
    "variable": "var(--colors-m3-surface012)"
  },
  "colors.m3.surface038": {
    "value": "var(--colors-m3-surface038)",
    "variable": "var(--colors-m3-surface038)"
  },
  "colors.m3.surface090": {
    "value": "var(--colors-m3-surface090)",
    "variable": "var(--colors-m3-surface090)"
  },
  "colors.m3.onSurface": {
    "value": "var(--colors-m3-on-surface)",
    "variable": "var(--colors-m3-on-surface)"
  },
  "colors.m3.onSurface008": {
    "value": "var(--colors-m3-on-surface008)",
    "variable": "var(--colors-m3-on-surface008)"
  },
  "colors.m3.onSurface012": {
    "value": "var(--colors-m3-on-surface012)",
    "variable": "var(--colors-m3-on-surface012)"
  },
  "colors.m3.onSurface038": {
    "value": "var(--colors-m3-on-surface038)",
    "variable": "var(--colors-m3-on-surface038)"
  },
  "colors.m3.onSurface090": {
    "value": "var(--colors-m3-on-surface090)",
    "variable": "var(--colors-m3-on-surface090)"
  },
  "colors.m3.surfaceVariant": {
    "value": "var(--colors-m3-surface-variant)",
    "variable": "var(--colors-m3-surface-variant)"
  },
  "colors.m3.surfaceVariant008": {
    "value": "var(--colors-m3-surface-variant008)",
    "variable": "var(--colors-m3-surface-variant008)"
  },
  "colors.m3.surfaceVariant012": {
    "value": "var(--colors-m3-surface-variant012)",
    "variable": "var(--colors-m3-surface-variant012)"
  },
  "colors.m3.surfaceVariant038": {
    "value": "var(--colors-m3-surface-variant038)",
    "variable": "var(--colors-m3-surface-variant038)"
  },
  "colors.m3.surfaceVariant090": {
    "value": "var(--colors-m3-surface-variant090)",
    "variable": "var(--colors-m3-surface-variant090)"
  },
  "colors.m3.onSurfaceVariant": {
    "value": "var(--colors-m3-on-surface-variant)",
    "variable": "var(--colors-m3-on-surface-variant)"
  },
  "colors.m3.onSurfaceVariant008": {
    "value": "var(--colors-m3-on-surface-variant008)",
    "variable": "var(--colors-m3-on-surface-variant008)"
  },
  "colors.m3.onSurfaceVariant012": {
    "value": "var(--colors-m3-on-surface-variant012)",
    "variable": "var(--colors-m3-on-surface-variant012)"
  },
  "colors.m3.onSurfaceVariant038": {
    "value": "var(--colors-m3-on-surface-variant038)",
    "variable": "var(--colors-m3-on-surface-variant038)"
  },
  "colors.m3.onSurfaceVariant090": {
    "value": "var(--colors-m3-on-surface-variant090)",
    "variable": "var(--colors-m3-on-surface-variant090)"
  },
  "colors.m3.inverseSurface": {
    "value": "var(--colors-m3-inverse-surface)",
    "variable": "var(--colors-m3-inverse-surface)"
  },
  "colors.m3.inverseSurface008": {
    "value": "var(--colors-m3-inverse-surface008)",
    "variable": "var(--colors-m3-inverse-surface008)"
  },
  "colors.m3.inverseSurface012": {
    "value": "var(--colors-m3-inverse-surface012)",
    "variable": "var(--colors-m3-inverse-surface012)"
  },
  "colors.m3.inverseSurface038": {
    "value": "var(--colors-m3-inverse-surface038)",
    "variable": "var(--colors-m3-inverse-surface038)"
  },
  "colors.m3.inverseSurface090": {
    "value": "var(--colors-m3-inverse-surface090)",
    "variable": "var(--colors-m3-inverse-surface090)"
  },
  "colors.m3.inverseOnSurface": {
    "value": "var(--colors-m3-inverse-on-surface)",
    "variable": "var(--colors-m3-inverse-on-surface)"
  },
  "colors.m3.inverseOnSurface008": {
    "value": "var(--colors-m3-inverse-on-surface008)",
    "variable": "var(--colors-m3-inverse-on-surface008)"
  },
  "colors.m3.inverseOnSurface012": {
    "value": "var(--colors-m3-inverse-on-surface012)",
    "variable": "var(--colors-m3-inverse-on-surface012)"
  },
  "colors.m3.inverseOnSurface038": {
    "value": "var(--colors-m3-inverse-on-surface038)",
    "variable": "var(--colors-m3-inverse-on-surface038)"
  },
  "colors.m3.inverseOnSurface090": {
    "value": "var(--colors-m3-inverse-on-surface090)",
    "variable": "var(--colors-m3-inverse-on-surface090)"
  },
  "colors.m3.inversePrimary": {
    "value": "var(--colors-m3-inverse-primary)",
    "variable": "var(--colors-m3-inverse-primary)"
  },
  "colors.m3.inversePrimary008": {
    "value": "var(--colors-m3-inverse-primary008)",
    "variable": "var(--colors-m3-inverse-primary008)"
  },
  "colors.m3.inversePrimary012": {
    "value": "var(--colors-m3-inverse-primary012)",
    "variable": "var(--colors-m3-inverse-primary012)"
  },
  "colors.m3.inversePrimary038": {
    "value": "var(--colors-m3-inverse-primary038)",
    "variable": "var(--colors-m3-inverse-primary038)"
  },
  "colors.m3.inversePrimary090": {
    "value": "var(--colors-m3-inverse-primary090)",
    "variable": "var(--colors-m3-inverse-primary090)"
  },
  "colors.m3.shadow": {
    "value": "var(--colors-m3-shadow)",
    "variable": "var(--colors-m3-shadow)"
  },
  "colors.m3.shadow008": {
    "value": "var(--colors-m3-shadow008)",
    "variable": "var(--colors-m3-shadow008)"
  },
  "colors.m3.shadow012": {
    "value": "var(--colors-m3-shadow012)",
    "variable": "var(--colors-m3-shadow012)"
  },
  "colors.m3.shadow038": {
    "value": "var(--colors-m3-shadow038)",
    "variable": "var(--colors-m3-shadow038)"
  },
  "colors.m3.shadow090": {
    "value": "var(--colors-m3-shadow090)",
    "variable": "var(--colors-m3-shadow090)"
  },
  "colors.m3.surfaceTint": {
    "value": "var(--colors-m3-surface-tint)",
    "variable": "var(--colors-m3-surface-tint)"
  },
  "colors.m3.surfaceTint008": {
    "value": "var(--colors-m3-surface-tint008)",
    "variable": "var(--colors-m3-surface-tint008)"
  },
  "colors.m3.surfaceTint012": {
    "value": "var(--colors-m3-surface-tint012)",
    "variable": "var(--colors-m3-surface-tint012)"
  },
  "colors.m3.surfaceTint038": {
    "value": "var(--colors-m3-surface-tint038)",
    "variable": "var(--colors-m3-surface-tint038)"
  },
  "colors.m3.surfaceTint090": {
    "value": "var(--colors-m3-surface-tint090)",
    "variable": "var(--colors-m3-surface-tint090)"
  },
  "colors.m3.outlineVariant": {
    "value": "var(--colors-m3-outline-variant)",
    "variable": "var(--colors-m3-outline-variant)"
  },
  "colors.m3.outlineVariant008": {
    "value": "var(--colors-m3-outline-variant008)",
    "variable": "var(--colors-m3-outline-variant008)"
  },
  "colors.m3.outlineVariant012": {
    "value": "var(--colors-m3-outline-variant012)",
    "variable": "var(--colors-m3-outline-variant012)"
  },
  "colors.m3.outlineVariant038": {
    "value": "var(--colors-m3-outline-variant038)",
    "variable": "var(--colors-m3-outline-variant038)"
  },
  "colors.m3.outlineVariant090": {
    "value": "var(--colors-m3-outline-variant090)",
    "variable": "var(--colors-m3-outline-variant090)"
  },
  "colors.m3.scrim": {
    "value": "var(--colors-m3-scrim)",
    "variable": "var(--colors-m3-scrim)"
  },
  "colors.m3.scrim008": {
    "value": "var(--colors-m3-scrim008)",
    "variable": "var(--colors-m3-scrim008)"
  },
  "colors.m3.scrim012": {
    "value": "var(--colors-m3-scrim012)",
    "variable": "var(--colors-m3-scrim012)"
  },
  "colors.m3.scrim038": {
    "value": "var(--colors-m3-scrim038)",
    "variable": "var(--colors-m3-scrim038)"
  },
  "colors.m3.scrim090": {
    "value": "var(--colors-m3-scrim090)",
    "variable": "var(--colors-m3-scrim090)"
  },
  "colors.m3.surfaceContainerHighest": {
    "value": "var(--colors-m3-surface-container-highest)",
    "variable": "var(--colors-m3-surface-container-highest)"
  },
  "colors.m3.surfaceContainerHighest008": {
    "value": "var(--colors-m3-surface-container-highest008)",
    "variable": "var(--colors-m3-surface-container-highest008)"
  },
  "colors.m3.surfaceContainerHighest012": {
    "value": "var(--colors-m3-surface-container-highest012)",
    "variable": "var(--colors-m3-surface-container-highest012)"
  },
  "colors.m3.surfaceContainerHighest038": {
    "value": "var(--colors-m3-surface-container-highest038)",
    "variable": "var(--colors-m3-surface-container-highest038)"
  },
  "colors.m3.surfaceContainerHighest090": {
    "value": "var(--colors-m3-surface-container-highest090)",
    "variable": "var(--colors-m3-surface-container-highest090)"
  },
  "colors.m3.surfaceContainerHigh": {
    "value": "var(--colors-m3-surface-container-high)",
    "variable": "var(--colors-m3-surface-container-high)"
  },
  "colors.m3.surfaceContainerHigh008": {
    "value": "var(--colors-m3-surface-container-high008)",
    "variable": "var(--colors-m3-surface-container-high008)"
  },
  "colors.m3.surfaceContainerHigh012": {
    "value": "var(--colors-m3-surface-container-high012)",
    "variable": "var(--colors-m3-surface-container-high012)"
  },
  "colors.m3.surfaceContainerHigh038": {
    "value": "var(--colors-m3-surface-container-high038)",
    "variable": "var(--colors-m3-surface-container-high038)"
  },
  "colors.m3.surfaceContainerHigh090": {
    "value": "var(--colors-m3-surface-container-high090)",
    "variable": "var(--colors-m3-surface-container-high090)"
  },
  "colors.m3.surfaceContainer": {
    "value": "var(--colors-m3-surface-container)",
    "variable": "var(--colors-m3-surface-container)"
  },
  "colors.m3.surfaceContainer008": {
    "value": "var(--colors-m3-surface-container008)",
    "variable": "var(--colors-m3-surface-container008)"
  },
  "colors.m3.surfaceContainer012": {
    "value": "var(--colors-m3-surface-container012)",
    "variable": "var(--colors-m3-surface-container012)"
  },
  "colors.m3.surfaceContainer038": {
    "value": "var(--colors-m3-surface-container038)",
    "variable": "var(--colors-m3-surface-container038)"
  },
  "colors.m3.surfaceContainer090": {
    "value": "var(--colors-m3-surface-container090)",
    "variable": "var(--colors-m3-surface-container090)"
  },
  "colors.m3.surfaceContainerLow": {
    "value": "var(--colors-m3-surface-container-low)",
    "variable": "var(--colors-m3-surface-container-low)"
  },
  "colors.m3.surfaceContainerLow008": {
    "value": "var(--colors-m3-surface-container-low008)",
    "variable": "var(--colors-m3-surface-container-low008)"
  },
  "colors.m3.surfaceContainerLow012": {
    "value": "var(--colors-m3-surface-container-low012)",
    "variable": "var(--colors-m3-surface-container-low012)"
  },
  "colors.m3.surfaceContainerLow038": {
    "value": "var(--colors-m3-surface-container-low038)",
    "variable": "var(--colors-m3-surface-container-low038)"
  },
  "colors.m3.surfaceContainerLow090": {
    "value": "var(--colors-m3-surface-container-low090)",
    "variable": "var(--colors-m3-surface-container-low090)"
  },
  "colors.m3.surfaceContainerLowest": {
    "value": "var(--colors-m3-surface-container-lowest)",
    "variable": "var(--colors-m3-surface-container-lowest)"
  },
  "colors.m3.surfaceContainerLowest008": {
    "value": "var(--colors-m3-surface-container-lowest008)",
    "variable": "var(--colors-m3-surface-container-lowest008)"
  },
  "colors.m3.surfaceContainerLowest012": {
    "value": "var(--colors-m3-surface-container-lowest012)",
    "variable": "var(--colors-m3-surface-container-lowest012)"
  },
  "colors.m3.surfaceContainerLowest038": {
    "value": "var(--colors-m3-surface-container-lowest038)",
    "variable": "var(--colors-m3-surface-container-lowest038)"
  },
  "colors.m3.surfaceContainerLowest090": {
    "value": "var(--colors-m3-surface-container-lowest090)",
    "variable": "var(--colors-m3-surface-container-lowest090)"
  },
  "colors.m3.surfaceBright": {
    "value": "var(--colors-m3-surface-bright)",
    "variable": "var(--colors-m3-surface-bright)"
  },
  "colors.m3.surfaceBright008": {
    "value": "var(--colors-m3-surface-bright008)",
    "variable": "var(--colors-m3-surface-bright008)"
  },
  "colors.m3.surfaceBright012": {
    "value": "var(--colors-m3-surface-bright012)",
    "variable": "var(--colors-m3-surface-bright012)"
  },
  "colors.m3.surfaceBright038": {
    "value": "var(--colors-m3-surface-bright038)",
    "variable": "var(--colors-m3-surface-bright038)"
  },
  "colors.m3.surfaceBright090": {
    "value": "var(--colors-m3-surface-bright090)",
    "variable": "var(--colors-m3-surface-bright090)"
  },
  "colors.m3.surfaceDim": {
    "value": "var(--colors-m3-surface-dim)",
    "variable": "var(--colors-m3-surface-dim)"
  },
  "colors.m3.surfaceDim008": {
    "value": "var(--colors-m3-surface-dim008)",
    "variable": "var(--colors-m3-surface-dim008)"
  },
  "colors.m3.surfaceDim012": {
    "value": "var(--colors-m3-surface-dim012)",
    "variable": "var(--colors-m3-surface-dim012)"
  },
  "colors.m3.surfaceDim038": {
    "value": "var(--colors-m3-surface-dim038)",
    "variable": "var(--colors-m3-surface-dim038)"
  },
  "colors.m3.surfaceDim090": {
    "value": "var(--colors-m3-surface-dim090)",
    "variable": "var(--colors-m3-surface-dim090)"
  },
  "shadows.m3.elevation.1": {
    "value": "var(--shadows-m3-elevation-1)",
    "variable": "var(--shadows-m3-elevation-1)"
  },
  "shadows.m3.elevation.2": {
    "value": "var(--shadows-m3-elevation-2)",
    "variable": "var(--shadows-m3-elevation-2)"
  },
  "shadows.m3.elevation.3": {
    "value": "var(--shadows-m3-elevation-3)",
    "variable": "var(--shadows-m3-elevation-3)"
  },
  "shadows.m3.elevation.4": {
    "value": "var(--shadows-m3-elevation-4)",
    "variable": "var(--shadows-m3-elevation-4)"
  },
  "shadows.m3.elevation.5": {
    "value": "var(--shadows-m3-elevation-5)",
    "variable": "var(--shadows-m3-elevation-5)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-3": {
    "value": "calc(var(--spacing-3) * -1)",
    "variable": "var(--spacing-3)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-5": {
    "value": "calc(var(--spacing-5) * -1)",
    "variable": "var(--spacing-5)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-7": {
    "value": "calc(var(--spacing-7) * -1)",
    "variable": "var(--spacing-7)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-9": {
    "value": "calc(var(--spacing-9) * -1)",
    "variable": "var(--spacing-9)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-11": {
    "value": "calc(var(--spacing-11) * -1)",
    "variable": "var(--spacing-11)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-52": {
    "value": "calc(var(--spacing-52) * -1)",
    "variable": "var(--spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--spacing-60) * -1)",
    "variable": "var(--spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-72": {
    "value": "calc(var(--spacing-72) * -1)",
    "variable": "var(--spacing-72)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-0.5": {
    "value": "calc(var(--spacing-0\\.5) * -1)",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.-1.5": {
    "value": "calc(var(--spacing-1\\.5) * -1)",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.-2.5": {
    "value": "calc(var(--spacing-2\\.5) * -1)",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.-3.5": {
    "value": "calc(var(--spacing-3\\.5) * -1)",
    "variable": "var(--spacing-3\\.5)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.950": {
    "value": "var(--colors-color-palette-950)",
    "variable": "var(--colors-color-palette-950)"
  },
  "colors.colorPalette.primary": {
    "value": "var(--colors-color-palette-primary)",
    "variable": "var(--colors-color-palette-primary)"
  },
  "colors.colorPalette.primary008": {
    "value": "var(--colors-color-palette-primary008)",
    "variable": "var(--colors-color-palette-primary008)"
  },
  "colors.colorPalette.primary012": {
    "value": "var(--colors-color-palette-primary012)",
    "variable": "var(--colors-color-palette-primary012)"
  },
  "colors.colorPalette.primary038": {
    "value": "var(--colors-color-palette-primary038)",
    "variable": "var(--colors-color-palette-primary038)"
  },
  "colors.colorPalette.primary090": {
    "value": "var(--colors-color-palette-primary090)",
    "variable": "var(--colors-color-palette-primary090)"
  },
  "colors.colorPalette.onPrimary": {
    "value": "var(--colors-color-palette-on-primary)",
    "variable": "var(--colors-color-palette-on-primary)"
  },
  "colors.colorPalette.onPrimary008": {
    "value": "var(--colors-color-palette-on-primary008)",
    "variable": "var(--colors-color-palette-on-primary008)"
  },
  "colors.colorPalette.onPrimary012": {
    "value": "var(--colors-color-palette-on-primary012)",
    "variable": "var(--colors-color-palette-on-primary012)"
  },
  "colors.colorPalette.onPrimary038": {
    "value": "var(--colors-color-palette-on-primary038)",
    "variable": "var(--colors-color-palette-on-primary038)"
  },
  "colors.colorPalette.onPrimary090": {
    "value": "var(--colors-color-palette-on-primary090)",
    "variable": "var(--colors-color-palette-on-primary090)"
  },
  "colors.colorPalette.primaryContainer": {
    "value": "var(--colors-color-palette-primary-container)",
    "variable": "var(--colors-color-palette-primary-container)"
  },
  "colors.colorPalette.primaryContainer008": {
    "value": "var(--colors-color-palette-primary-container008)",
    "variable": "var(--colors-color-palette-primary-container008)"
  },
  "colors.colorPalette.primaryContainer012": {
    "value": "var(--colors-color-palette-primary-container012)",
    "variable": "var(--colors-color-palette-primary-container012)"
  },
  "colors.colorPalette.primaryContainer038": {
    "value": "var(--colors-color-palette-primary-container038)",
    "variable": "var(--colors-color-palette-primary-container038)"
  },
  "colors.colorPalette.primaryContainer090": {
    "value": "var(--colors-color-palette-primary-container090)",
    "variable": "var(--colors-color-palette-primary-container090)"
  },
  "colors.colorPalette.onPrimaryContainer": {
    "value": "var(--colors-color-palette-on-primary-container)",
    "variable": "var(--colors-color-palette-on-primary-container)"
  },
  "colors.colorPalette.onPrimaryContainer008": {
    "value": "var(--colors-color-palette-on-primary-container008)",
    "variable": "var(--colors-color-palette-on-primary-container008)"
  },
  "colors.colorPalette.onPrimaryContainer012": {
    "value": "var(--colors-color-palette-on-primary-container012)",
    "variable": "var(--colors-color-palette-on-primary-container012)"
  },
  "colors.colorPalette.onPrimaryContainer038": {
    "value": "var(--colors-color-palette-on-primary-container038)",
    "variable": "var(--colors-color-palette-on-primary-container038)"
  },
  "colors.colorPalette.onPrimaryContainer090": {
    "value": "var(--colors-color-palette-on-primary-container090)",
    "variable": "var(--colors-color-palette-on-primary-container090)"
  },
  "colors.colorPalette.primaryFixed": {
    "value": "var(--colors-color-palette-primary-fixed)",
    "variable": "var(--colors-color-palette-primary-fixed)"
  },
  "colors.colorPalette.primaryFixed008": {
    "value": "var(--colors-color-palette-primary-fixed008)",
    "variable": "var(--colors-color-palette-primary-fixed008)"
  },
  "colors.colorPalette.primaryFixed012": {
    "value": "var(--colors-color-palette-primary-fixed012)",
    "variable": "var(--colors-color-palette-primary-fixed012)"
  },
  "colors.colorPalette.primaryFixed038": {
    "value": "var(--colors-color-palette-primary-fixed038)",
    "variable": "var(--colors-color-palette-primary-fixed038)"
  },
  "colors.colorPalette.primaryFixed090": {
    "value": "var(--colors-color-palette-primary-fixed090)",
    "variable": "var(--colors-color-palette-primary-fixed090)"
  },
  "colors.colorPalette.onPrimaryFixed": {
    "value": "var(--colors-color-palette-on-primary-fixed)",
    "variable": "var(--colors-color-palette-on-primary-fixed)"
  },
  "colors.colorPalette.onPrimaryFixed008": {
    "value": "var(--colors-color-palette-on-primary-fixed008)",
    "variable": "var(--colors-color-palette-on-primary-fixed008)"
  },
  "colors.colorPalette.onPrimaryFixed012": {
    "value": "var(--colors-color-palette-on-primary-fixed012)",
    "variable": "var(--colors-color-palette-on-primary-fixed012)"
  },
  "colors.colorPalette.onPrimaryFixed038": {
    "value": "var(--colors-color-palette-on-primary-fixed038)",
    "variable": "var(--colors-color-palette-on-primary-fixed038)"
  },
  "colors.colorPalette.onPrimaryFixed090": {
    "value": "var(--colors-color-palette-on-primary-fixed090)",
    "variable": "var(--colors-color-palette-on-primary-fixed090)"
  },
  "colors.colorPalette.primaryFixedDim": {
    "value": "var(--colors-color-palette-primary-fixed-dim)",
    "variable": "var(--colors-color-palette-primary-fixed-dim)"
  },
  "colors.colorPalette.primaryFixedDim008": {
    "value": "var(--colors-color-palette-primary-fixed-dim008)",
    "variable": "var(--colors-color-palette-primary-fixed-dim008)"
  },
  "colors.colorPalette.primaryFixedDim012": {
    "value": "var(--colors-color-palette-primary-fixed-dim012)",
    "variable": "var(--colors-color-palette-primary-fixed-dim012)"
  },
  "colors.colorPalette.primaryFixedDim038": {
    "value": "var(--colors-color-palette-primary-fixed-dim038)",
    "variable": "var(--colors-color-palette-primary-fixed-dim038)"
  },
  "colors.colorPalette.primaryFixedDim090": {
    "value": "var(--colors-color-palette-primary-fixed-dim090)",
    "variable": "var(--colors-color-palette-primary-fixed-dim090)"
  },
  "colors.colorPalette.onPrimaryFixedVariant": {
    "value": "var(--colors-color-palette-on-primary-fixed-variant)",
    "variable": "var(--colors-color-palette-on-primary-fixed-variant)"
  },
  "colors.colorPalette.onPrimaryFixedVariant008": {
    "value": "var(--colors-color-palette-on-primary-fixed-variant008)",
    "variable": "var(--colors-color-palette-on-primary-fixed-variant008)"
  },
  "colors.colorPalette.onPrimaryFixedVariant012": {
    "value": "var(--colors-color-palette-on-primary-fixed-variant012)",
    "variable": "var(--colors-color-palette-on-primary-fixed-variant012)"
  },
  "colors.colorPalette.onPrimaryFixedVariant038": {
    "value": "var(--colors-color-palette-on-primary-fixed-variant038)",
    "variable": "var(--colors-color-palette-on-primary-fixed-variant038)"
  },
  "colors.colorPalette.onPrimaryFixedVariant090": {
    "value": "var(--colors-color-palette-on-primary-fixed-variant090)",
    "variable": "var(--colors-color-palette-on-primary-fixed-variant090)"
  },
  "colors.colorPalette.secondary": {
    "value": "var(--colors-color-palette-secondary)",
    "variable": "var(--colors-color-palette-secondary)"
  },
  "colors.colorPalette.secondary008": {
    "value": "var(--colors-color-palette-secondary008)",
    "variable": "var(--colors-color-palette-secondary008)"
  },
  "colors.colorPalette.secondary012": {
    "value": "var(--colors-color-palette-secondary012)",
    "variable": "var(--colors-color-palette-secondary012)"
  },
  "colors.colorPalette.secondary038": {
    "value": "var(--colors-color-palette-secondary038)",
    "variable": "var(--colors-color-palette-secondary038)"
  },
  "colors.colorPalette.secondary090": {
    "value": "var(--colors-color-palette-secondary090)",
    "variable": "var(--colors-color-palette-secondary090)"
  },
  "colors.colorPalette.onSecondary": {
    "value": "var(--colors-color-palette-on-secondary)",
    "variable": "var(--colors-color-palette-on-secondary)"
  },
  "colors.colorPalette.onSecondary008": {
    "value": "var(--colors-color-palette-on-secondary008)",
    "variable": "var(--colors-color-palette-on-secondary008)"
  },
  "colors.colorPalette.onSecondary012": {
    "value": "var(--colors-color-palette-on-secondary012)",
    "variable": "var(--colors-color-palette-on-secondary012)"
  },
  "colors.colorPalette.onSecondary038": {
    "value": "var(--colors-color-palette-on-secondary038)",
    "variable": "var(--colors-color-palette-on-secondary038)"
  },
  "colors.colorPalette.onSecondary090": {
    "value": "var(--colors-color-palette-on-secondary090)",
    "variable": "var(--colors-color-palette-on-secondary090)"
  },
  "colors.colorPalette.secondaryContainer": {
    "value": "var(--colors-color-palette-secondary-container)",
    "variable": "var(--colors-color-palette-secondary-container)"
  },
  "colors.colorPalette.secondaryContainer008": {
    "value": "var(--colors-color-palette-secondary-container008)",
    "variable": "var(--colors-color-palette-secondary-container008)"
  },
  "colors.colorPalette.secondaryContainer012": {
    "value": "var(--colors-color-palette-secondary-container012)",
    "variable": "var(--colors-color-palette-secondary-container012)"
  },
  "colors.colorPalette.secondaryContainer038": {
    "value": "var(--colors-color-palette-secondary-container038)",
    "variable": "var(--colors-color-palette-secondary-container038)"
  },
  "colors.colorPalette.secondaryContainer090": {
    "value": "var(--colors-color-palette-secondary-container090)",
    "variable": "var(--colors-color-palette-secondary-container090)"
  },
  "colors.colorPalette.onSecondaryContainer": {
    "value": "var(--colors-color-palette-on-secondary-container)",
    "variable": "var(--colors-color-palette-on-secondary-container)"
  },
  "colors.colorPalette.onSecondaryContainer008": {
    "value": "var(--colors-color-palette-on-secondary-container008)",
    "variable": "var(--colors-color-palette-on-secondary-container008)"
  },
  "colors.colorPalette.onSecondaryContainer012": {
    "value": "var(--colors-color-palette-on-secondary-container012)",
    "variable": "var(--colors-color-palette-on-secondary-container012)"
  },
  "colors.colorPalette.onSecondaryContainer038": {
    "value": "var(--colors-color-palette-on-secondary-container038)",
    "variable": "var(--colors-color-palette-on-secondary-container038)"
  },
  "colors.colorPalette.onSecondaryContainer090": {
    "value": "var(--colors-color-palette-on-secondary-container090)",
    "variable": "var(--colors-color-palette-on-secondary-container090)"
  },
  "colors.colorPalette.secondaryFixed": {
    "value": "var(--colors-color-palette-secondary-fixed)",
    "variable": "var(--colors-color-palette-secondary-fixed)"
  },
  "colors.colorPalette.secondaryFixed008": {
    "value": "var(--colors-color-palette-secondary-fixed008)",
    "variable": "var(--colors-color-palette-secondary-fixed008)"
  },
  "colors.colorPalette.secondaryFixed012": {
    "value": "var(--colors-color-palette-secondary-fixed012)",
    "variable": "var(--colors-color-palette-secondary-fixed012)"
  },
  "colors.colorPalette.secondaryFixed038": {
    "value": "var(--colors-color-palette-secondary-fixed038)",
    "variable": "var(--colors-color-palette-secondary-fixed038)"
  },
  "colors.colorPalette.secondaryFixed090": {
    "value": "var(--colors-color-palette-secondary-fixed090)",
    "variable": "var(--colors-color-palette-secondary-fixed090)"
  },
  "colors.colorPalette.onSecondaryFixed": {
    "value": "var(--colors-color-palette-on-secondary-fixed)",
    "variable": "var(--colors-color-palette-on-secondary-fixed)"
  },
  "colors.colorPalette.onSecondaryFixed008": {
    "value": "var(--colors-color-palette-on-secondary-fixed008)",
    "variable": "var(--colors-color-palette-on-secondary-fixed008)"
  },
  "colors.colorPalette.onSecondaryFixed012": {
    "value": "var(--colors-color-palette-on-secondary-fixed012)",
    "variable": "var(--colors-color-palette-on-secondary-fixed012)"
  },
  "colors.colorPalette.onSecondaryFixed038": {
    "value": "var(--colors-color-palette-on-secondary-fixed038)",
    "variable": "var(--colors-color-palette-on-secondary-fixed038)"
  },
  "colors.colorPalette.onSecondaryFixed090": {
    "value": "var(--colors-color-palette-on-secondary-fixed090)",
    "variable": "var(--colors-color-palette-on-secondary-fixed090)"
  },
  "colors.colorPalette.secondaryFixedDim": {
    "value": "var(--colors-color-palette-secondary-fixed-dim)",
    "variable": "var(--colors-color-palette-secondary-fixed-dim)"
  },
  "colors.colorPalette.secondaryFixedDim008": {
    "value": "var(--colors-color-palette-secondary-fixed-dim008)",
    "variable": "var(--colors-color-palette-secondary-fixed-dim008)"
  },
  "colors.colorPalette.secondaryFixedDim012": {
    "value": "var(--colors-color-palette-secondary-fixed-dim012)",
    "variable": "var(--colors-color-palette-secondary-fixed-dim012)"
  },
  "colors.colorPalette.secondaryFixedDim038": {
    "value": "var(--colors-color-palette-secondary-fixed-dim038)",
    "variable": "var(--colors-color-palette-secondary-fixed-dim038)"
  },
  "colors.colorPalette.secondaryFixedDim090": {
    "value": "var(--colors-color-palette-secondary-fixed-dim090)",
    "variable": "var(--colors-color-palette-secondary-fixed-dim090)"
  },
  "colors.colorPalette.onSecondaryFixedVariant": {
    "value": "var(--colors-color-palette-on-secondary-fixed-variant)",
    "variable": "var(--colors-color-palette-on-secondary-fixed-variant)"
  },
  "colors.colorPalette.onSecondaryFixedVariant008": {
    "value": "var(--colors-color-palette-on-secondary-fixed-variant008)",
    "variable": "var(--colors-color-palette-on-secondary-fixed-variant008)"
  },
  "colors.colorPalette.onSecondaryFixedVariant012": {
    "value": "var(--colors-color-palette-on-secondary-fixed-variant012)",
    "variable": "var(--colors-color-palette-on-secondary-fixed-variant012)"
  },
  "colors.colorPalette.onSecondaryFixedVariant038": {
    "value": "var(--colors-color-palette-on-secondary-fixed-variant038)",
    "variable": "var(--colors-color-palette-on-secondary-fixed-variant038)"
  },
  "colors.colorPalette.onSecondaryFixedVariant090": {
    "value": "var(--colors-color-palette-on-secondary-fixed-variant090)",
    "variable": "var(--colors-color-palette-on-secondary-fixed-variant090)"
  },
  "colors.colorPalette.tertiary": {
    "value": "var(--colors-color-palette-tertiary)",
    "variable": "var(--colors-color-palette-tertiary)"
  },
  "colors.colorPalette.tertiary008": {
    "value": "var(--colors-color-palette-tertiary008)",
    "variable": "var(--colors-color-palette-tertiary008)"
  },
  "colors.colorPalette.tertiary012": {
    "value": "var(--colors-color-palette-tertiary012)",
    "variable": "var(--colors-color-palette-tertiary012)"
  },
  "colors.colorPalette.tertiary038": {
    "value": "var(--colors-color-palette-tertiary038)",
    "variable": "var(--colors-color-palette-tertiary038)"
  },
  "colors.colorPalette.tertiary090": {
    "value": "var(--colors-color-palette-tertiary090)",
    "variable": "var(--colors-color-palette-tertiary090)"
  },
  "colors.colorPalette.onTertiary": {
    "value": "var(--colors-color-palette-on-tertiary)",
    "variable": "var(--colors-color-palette-on-tertiary)"
  },
  "colors.colorPalette.onTertiary008": {
    "value": "var(--colors-color-palette-on-tertiary008)",
    "variable": "var(--colors-color-palette-on-tertiary008)"
  },
  "colors.colorPalette.onTertiary012": {
    "value": "var(--colors-color-palette-on-tertiary012)",
    "variable": "var(--colors-color-palette-on-tertiary012)"
  },
  "colors.colorPalette.onTertiary038": {
    "value": "var(--colors-color-palette-on-tertiary038)",
    "variable": "var(--colors-color-palette-on-tertiary038)"
  },
  "colors.colorPalette.onTertiary090": {
    "value": "var(--colors-color-palette-on-tertiary090)",
    "variable": "var(--colors-color-palette-on-tertiary090)"
  },
  "colors.colorPalette.tertiaryContainer": {
    "value": "var(--colors-color-palette-tertiary-container)",
    "variable": "var(--colors-color-palette-tertiary-container)"
  },
  "colors.colorPalette.tertiaryContainer008": {
    "value": "var(--colors-color-palette-tertiary-container008)",
    "variable": "var(--colors-color-palette-tertiary-container008)"
  },
  "colors.colorPalette.tertiaryContainer012": {
    "value": "var(--colors-color-palette-tertiary-container012)",
    "variable": "var(--colors-color-palette-tertiary-container012)"
  },
  "colors.colorPalette.tertiaryContainer038": {
    "value": "var(--colors-color-palette-tertiary-container038)",
    "variable": "var(--colors-color-palette-tertiary-container038)"
  },
  "colors.colorPalette.tertiaryContainer090": {
    "value": "var(--colors-color-palette-tertiary-container090)",
    "variable": "var(--colors-color-palette-tertiary-container090)"
  },
  "colors.colorPalette.onTertiaryContainer": {
    "value": "var(--colors-color-palette-on-tertiary-container)",
    "variable": "var(--colors-color-palette-on-tertiary-container)"
  },
  "colors.colorPalette.onTertiaryContainer008": {
    "value": "var(--colors-color-palette-on-tertiary-container008)",
    "variable": "var(--colors-color-palette-on-tertiary-container008)"
  },
  "colors.colorPalette.onTertiaryContainer012": {
    "value": "var(--colors-color-palette-on-tertiary-container012)",
    "variable": "var(--colors-color-palette-on-tertiary-container012)"
  },
  "colors.colorPalette.onTertiaryContainer038": {
    "value": "var(--colors-color-palette-on-tertiary-container038)",
    "variable": "var(--colors-color-palette-on-tertiary-container038)"
  },
  "colors.colorPalette.onTertiaryContainer090": {
    "value": "var(--colors-color-palette-on-tertiary-container090)",
    "variable": "var(--colors-color-palette-on-tertiary-container090)"
  },
  "colors.colorPalette.tertiaryFixed": {
    "value": "var(--colors-color-palette-tertiary-fixed)",
    "variable": "var(--colors-color-palette-tertiary-fixed)"
  },
  "colors.colorPalette.tertiaryFixed008": {
    "value": "var(--colors-color-palette-tertiary-fixed008)",
    "variable": "var(--colors-color-palette-tertiary-fixed008)"
  },
  "colors.colorPalette.tertiaryFixed012": {
    "value": "var(--colors-color-palette-tertiary-fixed012)",
    "variable": "var(--colors-color-palette-tertiary-fixed012)"
  },
  "colors.colorPalette.tertiaryFixed038": {
    "value": "var(--colors-color-palette-tertiary-fixed038)",
    "variable": "var(--colors-color-palette-tertiary-fixed038)"
  },
  "colors.colorPalette.tertiaryFixed090": {
    "value": "var(--colors-color-palette-tertiary-fixed090)",
    "variable": "var(--colors-color-palette-tertiary-fixed090)"
  },
  "colors.colorPalette.onTertiaryFixed": {
    "value": "var(--colors-color-palette-on-tertiary-fixed)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed)"
  },
  "colors.colorPalette.onTertiaryFixed008": {
    "value": "var(--colors-color-palette-on-tertiary-fixed008)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed008)"
  },
  "colors.colorPalette.onTertiaryFixed012": {
    "value": "var(--colors-color-palette-on-tertiary-fixed012)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed012)"
  },
  "colors.colorPalette.onTertiaryFixed038": {
    "value": "var(--colors-color-palette-on-tertiary-fixed038)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed038)"
  },
  "colors.colorPalette.onTertiaryFixed090": {
    "value": "var(--colors-color-palette-on-tertiary-fixed090)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed090)"
  },
  "colors.colorPalette.tertiaryFixedDim": {
    "value": "var(--colors-color-palette-tertiary-fixed-dim)",
    "variable": "var(--colors-color-palette-tertiary-fixed-dim)"
  },
  "colors.colorPalette.tertiaryFixedDim008": {
    "value": "var(--colors-color-palette-tertiary-fixed-dim008)",
    "variable": "var(--colors-color-palette-tertiary-fixed-dim008)"
  },
  "colors.colorPalette.tertiaryFixedDim012": {
    "value": "var(--colors-color-palette-tertiary-fixed-dim012)",
    "variable": "var(--colors-color-palette-tertiary-fixed-dim012)"
  },
  "colors.colorPalette.tertiaryFixedDim038": {
    "value": "var(--colors-color-palette-tertiary-fixed-dim038)",
    "variable": "var(--colors-color-palette-tertiary-fixed-dim038)"
  },
  "colors.colorPalette.tertiaryFixedDim090": {
    "value": "var(--colors-color-palette-tertiary-fixed-dim090)",
    "variable": "var(--colors-color-palette-tertiary-fixed-dim090)"
  },
  "colors.colorPalette.onTertiaryFixedVariant": {
    "value": "var(--colors-color-palette-on-tertiary-fixed-variant)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed-variant)"
  },
  "colors.colorPalette.onTertiaryFixedVariant008": {
    "value": "var(--colors-color-palette-on-tertiary-fixed-variant008)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed-variant008)"
  },
  "colors.colorPalette.onTertiaryFixedVariant012": {
    "value": "var(--colors-color-palette-on-tertiary-fixed-variant012)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed-variant012)"
  },
  "colors.colorPalette.onTertiaryFixedVariant038": {
    "value": "var(--colors-color-palette-on-tertiary-fixed-variant038)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed-variant038)"
  },
  "colors.colorPalette.onTertiaryFixedVariant090": {
    "value": "var(--colors-color-palette-on-tertiary-fixed-variant090)",
    "variable": "var(--colors-color-palette-on-tertiary-fixed-variant090)"
  },
  "colors.colorPalette.error": {
    "value": "var(--colors-color-palette-error)",
    "variable": "var(--colors-color-palette-error)"
  },
  "colors.colorPalette.error008": {
    "value": "var(--colors-color-palette-error008)",
    "variable": "var(--colors-color-palette-error008)"
  },
  "colors.colorPalette.error012": {
    "value": "var(--colors-color-palette-error012)",
    "variable": "var(--colors-color-palette-error012)"
  },
  "colors.colorPalette.error038": {
    "value": "var(--colors-color-palette-error038)",
    "variable": "var(--colors-color-palette-error038)"
  },
  "colors.colorPalette.error090": {
    "value": "var(--colors-color-palette-error090)",
    "variable": "var(--colors-color-palette-error090)"
  },
  "colors.colorPalette.onError": {
    "value": "var(--colors-color-palette-on-error)",
    "variable": "var(--colors-color-palette-on-error)"
  },
  "colors.colorPalette.onError008": {
    "value": "var(--colors-color-palette-on-error008)",
    "variable": "var(--colors-color-palette-on-error008)"
  },
  "colors.colorPalette.onError012": {
    "value": "var(--colors-color-palette-on-error012)",
    "variable": "var(--colors-color-palette-on-error012)"
  },
  "colors.colorPalette.onError038": {
    "value": "var(--colors-color-palette-on-error038)",
    "variable": "var(--colors-color-palette-on-error038)"
  },
  "colors.colorPalette.onError090": {
    "value": "var(--colors-color-palette-on-error090)",
    "variable": "var(--colors-color-palette-on-error090)"
  },
  "colors.colorPalette.errorContainer": {
    "value": "var(--colors-color-palette-error-container)",
    "variable": "var(--colors-color-palette-error-container)"
  },
  "colors.colorPalette.errorContainer008": {
    "value": "var(--colors-color-palette-error-container008)",
    "variable": "var(--colors-color-palette-error-container008)"
  },
  "colors.colorPalette.errorContainer012": {
    "value": "var(--colors-color-palette-error-container012)",
    "variable": "var(--colors-color-palette-error-container012)"
  },
  "colors.colorPalette.errorContainer038": {
    "value": "var(--colors-color-palette-error-container038)",
    "variable": "var(--colors-color-palette-error-container038)"
  },
  "colors.colorPalette.errorContainer090": {
    "value": "var(--colors-color-palette-error-container090)",
    "variable": "var(--colors-color-palette-error-container090)"
  },
  "colors.colorPalette.onErrorContainer": {
    "value": "var(--colors-color-palette-on-error-container)",
    "variable": "var(--colors-color-palette-on-error-container)"
  },
  "colors.colorPalette.onErrorContainer008": {
    "value": "var(--colors-color-palette-on-error-container008)",
    "variable": "var(--colors-color-palette-on-error-container008)"
  },
  "colors.colorPalette.onErrorContainer012": {
    "value": "var(--colors-color-palette-on-error-container012)",
    "variable": "var(--colors-color-palette-on-error-container012)"
  },
  "colors.colorPalette.onErrorContainer038": {
    "value": "var(--colors-color-palette-on-error-container038)",
    "variable": "var(--colors-color-palette-on-error-container038)"
  },
  "colors.colorPalette.onErrorContainer090": {
    "value": "var(--colors-color-palette-on-error-container090)",
    "variable": "var(--colors-color-palette-on-error-container090)"
  },
  "colors.colorPalette.outline": {
    "value": "var(--colors-color-palette-outline)",
    "variable": "var(--colors-color-palette-outline)"
  },
  "colors.colorPalette.outline008": {
    "value": "var(--colors-color-palette-outline008)",
    "variable": "var(--colors-color-palette-outline008)"
  },
  "colors.colorPalette.outline012": {
    "value": "var(--colors-color-palette-outline012)",
    "variable": "var(--colors-color-palette-outline012)"
  },
  "colors.colorPalette.outline038": {
    "value": "var(--colors-color-palette-outline038)",
    "variable": "var(--colors-color-palette-outline038)"
  },
  "colors.colorPalette.outline090": {
    "value": "var(--colors-color-palette-outline090)",
    "variable": "var(--colors-color-palette-outline090)"
  },
  "colors.colorPalette.background": {
    "value": "var(--colors-color-palette-background)",
    "variable": "var(--colors-color-palette-background)"
  },
  "colors.colorPalette.background008": {
    "value": "var(--colors-color-palette-background008)",
    "variable": "var(--colors-color-palette-background008)"
  },
  "colors.colorPalette.background012": {
    "value": "var(--colors-color-palette-background012)",
    "variable": "var(--colors-color-palette-background012)"
  },
  "colors.colorPalette.background038": {
    "value": "var(--colors-color-palette-background038)",
    "variable": "var(--colors-color-palette-background038)"
  },
  "colors.colorPalette.background090": {
    "value": "var(--colors-color-palette-background090)",
    "variable": "var(--colors-color-palette-background090)"
  },
  "colors.colorPalette.onBackground": {
    "value": "var(--colors-color-palette-on-background)",
    "variable": "var(--colors-color-palette-on-background)"
  },
  "colors.colorPalette.onBackground008": {
    "value": "var(--colors-color-palette-on-background008)",
    "variable": "var(--colors-color-palette-on-background008)"
  },
  "colors.colorPalette.onBackground012": {
    "value": "var(--colors-color-palette-on-background012)",
    "variable": "var(--colors-color-palette-on-background012)"
  },
  "colors.colorPalette.onBackground038": {
    "value": "var(--colors-color-palette-on-background038)",
    "variable": "var(--colors-color-palette-on-background038)"
  },
  "colors.colorPalette.onBackground090": {
    "value": "var(--colors-color-palette-on-background090)",
    "variable": "var(--colors-color-palette-on-background090)"
  },
  "colors.colorPalette.surface": {
    "value": "var(--colors-color-palette-surface)",
    "variable": "var(--colors-color-palette-surface)"
  },
  "colors.colorPalette.surface008": {
    "value": "var(--colors-color-palette-surface008)",
    "variable": "var(--colors-color-palette-surface008)"
  },
  "colors.colorPalette.surface012": {
    "value": "var(--colors-color-palette-surface012)",
    "variable": "var(--colors-color-palette-surface012)"
  },
  "colors.colorPalette.surface038": {
    "value": "var(--colors-color-palette-surface038)",
    "variable": "var(--colors-color-palette-surface038)"
  },
  "colors.colorPalette.surface090": {
    "value": "var(--colors-color-palette-surface090)",
    "variable": "var(--colors-color-palette-surface090)"
  },
  "colors.colorPalette.onSurface": {
    "value": "var(--colors-color-palette-on-surface)",
    "variable": "var(--colors-color-palette-on-surface)"
  },
  "colors.colorPalette.onSurface008": {
    "value": "var(--colors-color-palette-on-surface008)",
    "variable": "var(--colors-color-palette-on-surface008)"
  },
  "colors.colorPalette.onSurface012": {
    "value": "var(--colors-color-palette-on-surface012)",
    "variable": "var(--colors-color-palette-on-surface012)"
  },
  "colors.colorPalette.onSurface038": {
    "value": "var(--colors-color-palette-on-surface038)",
    "variable": "var(--colors-color-palette-on-surface038)"
  },
  "colors.colorPalette.onSurface090": {
    "value": "var(--colors-color-palette-on-surface090)",
    "variable": "var(--colors-color-palette-on-surface090)"
  },
  "colors.colorPalette.surfaceVariant": {
    "value": "var(--colors-color-palette-surface-variant)",
    "variable": "var(--colors-color-palette-surface-variant)"
  },
  "colors.colorPalette.surfaceVariant008": {
    "value": "var(--colors-color-palette-surface-variant008)",
    "variable": "var(--colors-color-palette-surface-variant008)"
  },
  "colors.colorPalette.surfaceVariant012": {
    "value": "var(--colors-color-palette-surface-variant012)",
    "variable": "var(--colors-color-palette-surface-variant012)"
  },
  "colors.colorPalette.surfaceVariant038": {
    "value": "var(--colors-color-palette-surface-variant038)",
    "variable": "var(--colors-color-palette-surface-variant038)"
  },
  "colors.colorPalette.surfaceVariant090": {
    "value": "var(--colors-color-palette-surface-variant090)",
    "variable": "var(--colors-color-palette-surface-variant090)"
  },
  "colors.colorPalette.onSurfaceVariant": {
    "value": "var(--colors-color-palette-on-surface-variant)",
    "variable": "var(--colors-color-palette-on-surface-variant)"
  },
  "colors.colorPalette.onSurfaceVariant008": {
    "value": "var(--colors-color-palette-on-surface-variant008)",
    "variable": "var(--colors-color-palette-on-surface-variant008)"
  },
  "colors.colorPalette.onSurfaceVariant012": {
    "value": "var(--colors-color-palette-on-surface-variant012)",
    "variable": "var(--colors-color-palette-on-surface-variant012)"
  },
  "colors.colorPalette.onSurfaceVariant038": {
    "value": "var(--colors-color-palette-on-surface-variant038)",
    "variable": "var(--colors-color-palette-on-surface-variant038)"
  },
  "colors.colorPalette.onSurfaceVariant090": {
    "value": "var(--colors-color-palette-on-surface-variant090)",
    "variable": "var(--colors-color-palette-on-surface-variant090)"
  },
  "colors.colorPalette.inverseSurface": {
    "value": "var(--colors-color-palette-inverse-surface)",
    "variable": "var(--colors-color-palette-inverse-surface)"
  },
  "colors.colorPalette.inverseSurface008": {
    "value": "var(--colors-color-palette-inverse-surface008)",
    "variable": "var(--colors-color-palette-inverse-surface008)"
  },
  "colors.colorPalette.inverseSurface012": {
    "value": "var(--colors-color-palette-inverse-surface012)",
    "variable": "var(--colors-color-palette-inverse-surface012)"
  },
  "colors.colorPalette.inverseSurface038": {
    "value": "var(--colors-color-palette-inverse-surface038)",
    "variable": "var(--colors-color-palette-inverse-surface038)"
  },
  "colors.colorPalette.inverseSurface090": {
    "value": "var(--colors-color-palette-inverse-surface090)",
    "variable": "var(--colors-color-palette-inverse-surface090)"
  },
  "colors.colorPalette.inverseOnSurface": {
    "value": "var(--colors-color-palette-inverse-on-surface)",
    "variable": "var(--colors-color-palette-inverse-on-surface)"
  },
  "colors.colorPalette.inverseOnSurface008": {
    "value": "var(--colors-color-palette-inverse-on-surface008)",
    "variable": "var(--colors-color-palette-inverse-on-surface008)"
  },
  "colors.colorPalette.inverseOnSurface012": {
    "value": "var(--colors-color-palette-inverse-on-surface012)",
    "variable": "var(--colors-color-palette-inverse-on-surface012)"
  },
  "colors.colorPalette.inverseOnSurface038": {
    "value": "var(--colors-color-palette-inverse-on-surface038)",
    "variable": "var(--colors-color-palette-inverse-on-surface038)"
  },
  "colors.colorPalette.inverseOnSurface090": {
    "value": "var(--colors-color-palette-inverse-on-surface090)",
    "variable": "var(--colors-color-palette-inverse-on-surface090)"
  },
  "colors.colorPalette.inversePrimary": {
    "value": "var(--colors-color-palette-inverse-primary)",
    "variable": "var(--colors-color-palette-inverse-primary)"
  },
  "colors.colorPalette.inversePrimary008": {
    "value": "var(--colors-color-palette-inverse-primary008)",
    "variable": "var(--colors-color-palette-inverse-primary008)"
  },
  "colors.colorPalette.inversePrimary012": {
    "value": "var(--colors-color-palette-inverse-primary012)",
    "variable": "var(--colors-color-palette-inverse-primary012)"
  },
  "colors.colorPalette.inversePrimary038": {
    "value": "var(--colors-color-palette-inverse-primary038)",
    "variable": "var(--colors-color-palette-inverse-primary038)"
  },
  "colors.colorPalette.inversePrimary090": {
    "value": "var(--colors-color-palette-inverse-primary090)",
    "variable": "var(--colors-color-palette-inverse-primary090)"
  },
  "colors.colorPalette.shadow": {
    "value": "var(--colors-color-palette-shadow)",
    "variable": "var(--colors-color-palette-shadow)"
  },
  "colors.colorPalette.shadow008": {
    "value": "var(--colors-color-palette-shadow008)",
    "variable": "var(--colors-color-palette-shadow008)"
  },
  "colors.colorPalette.shadow012": {
    "value": "var(--colors-color-palette-shadow012)",
    "variable": "var(--colors-color-palette-shadow012)"
  },
  "colors.colorPalette.shadow038": {
    "value": "var(--colors-color-palette-shadow038)",
    "variable": "var(--colors-color-palette-shadow038)"
  },
  "colors.colorPalette.shadow090": {
    "value": "var(--colors-color-palette-shadow090)",
    "variable": "var(--colors-color-palette-shadow090)"
  },
  "colors.colorPalette.surfaceTint": {
    "value": "var(--colors-color-palette-surface-tint)",
    "variable": "var(--colors-color-palette-surface-tint)"
  },
  "colors.colorPalette.surfaceTint008": {
    "value": "var(--colors-color-palette-surface-tint008)",
    "variable": "var(--colors-color-palette-surface-tint008)"
  },
  "colors.colorPalette.surfaceTint012": {
    "value": "var(--colors-color-palette-surface-tint012)",
    "variable": "var(--colors-color-palette-surface-tint012)"
  },
  "colors.colorPalette.surfaceTint038": {
    "value": "var(--colors-color-palette-surface-tint038)",
    "variable": "var(--colors-color-palette-surface-tint038)"
  },
  "colors.colorPalette.surfaceTint090": {
    "value": "var(--colors-color-palette-surface-tint090)",
    "variable": "var(--colors-color-palette-surface-tint090)"
  },
  "colors.colorPalette.outlineVariant": {
    "value": "var(--colors-color-palette-outline-variant)",
    "variable": "var(--colors-color-palette-outline-variant)"
  },
  "colors.colorPalette.outlineVariant008": {
    "value": "var(--colors-color-palette-outline-variant008)",
    "variable": "var(--colors-color-palette-outline-variant008)"
  },
  "colors.colorPalette.outlineVariant012": {
    "value": "var(--colors-color-palette-outline-variant012)",
    "variable": "var(--colors-color-palette-outline-variant012)"
  },
  "colors.colorPalette.outlineVariant038": {
    "value": "var(--colors-color-palette-outline-variant038)",
    "variable": "var(--colors-color-palette-outline-variant038)"
  },
  "colors.colorPalette.outlineVariant090": {
    "value": "var(--colors-color-palette-outline-variant090)",
    "variable": "var(--colors-color-palette-outline-variant090)"
  },
  "colors.colorPalette.scrim": {
    "value": "var(--colors-color-palette-scrim)",
    "variable": "var(--colors-color-palette-scrim)"
  },
  "colors.colorPalette.scrim008": {
    "value": "var(--colors-color-palette-scrim008)",
    "variable": "var(--colors-color-palette-scrim008)"
  },
  "colors.colorPalette.scrim012": {
    "value": "var(--colors-color-palette-scrim012)",
    "variable": "var(--colors-color-palette-scrim012)"
  },
  "colors.colorPalette.scrim038": {
    "value": "var(--colors-color-palette-scrim038)",
    "variable": "var(--colors-color-palette-scrim038)"
  },
  "colors.colorPalette.scrim090": {
    "value": "var(--colors-color-palette-scrim090)",
    "variable": "var(--colors-color-palette-scrim090)"
  },
  "colors.colorPalette.surfaceContainerHighest": {
    "value": "var(--colors-color-palette-surface-container-highest)",
    "variable": "var(--colors-color-palette-surface-container-highest)"
  },
  "colors.colorPalette.surfaceContainerHighest008": {
    "value": "var(--colors-color-palette-surface-container-highest008)",
    "variable": "var(--colors-color-palette-surface-container-highest008)"
  },
  "colors.colorPalette.surfaceContainerHighest012": {
    "value": "var(--colors-color-palette-surface-container-highest012)",
    "variable": "var(--colors-color-palette-surface-container-highest012)"
  },
  "colors.colorPalette.surfaceContainerHighest038": {
    "value": "var(--colors-color-palette-surface-container-highest038)",
    "variable": "var(--colors-color-palette-surface-container-highest038)"
  },
  "colors.colorPalette.surfaceContainerHighest090": {
    "value": "var(--colors-color-palette-surface-container-highest090)",
    "variable": "var(--colors-color-palette-surface-container-highest090)"
  },
  "colors.colorPalette.surfaceContainerHigh": {
    "value": "var(--colors-color-palette-surface-container-high)",
    "variable": "var(--colors-color-palette-surface-container-high)"
  },
  "colors.colorPalette.surfaceContainerHigh008": {
    "value": "var(--colors-color-palette-surface-container-high008)",
    "variable": "var(--colors-color-palette-surface-container-high008)"
  },
  "colors.colorPalette.surfaceContainerHigh012": {
    "value": "var(--colors-color-palette-surface-container-high012)",
    "variable": "var(--colors-color-palette-surface-container-high012)"
  },
  "colors.colorPalette.surfaceContainerHigh038": {
    "value": "var(--colors-color-palette-surface-container-high038)",
    "variable": "var(--colors-color-palette-surface-container-high038)"
  },
  "colors.colorPalette.surfaceContainerHigh090": {
    "value": "var(--colors-color-palette-surface-container-high090)",
    "variable": "var(--colors-color-palette-surface-container-high090)"
  },
  "colors.colorPalette.surfaceContainer": {
    "value": "var(--colors-color-palette-surface-container)",
    "variable": "var(--colors-color-palette-surface-container)"
  },
  "colors.colorPalette.surfaceContainer008": {
    "value": "var(--colors-color-palette-surface-container008)",
    "variable": "var(--colors-color-palette-surface-container008)"
  },
  "colors.colorPalette.surfaceContainer012": {
    "value": "var(--colors-color-palette-surface-container012)",
    "variable": "var(--colors-color-palette-surface-container012)"
  },
  "colors.colorPalette.surfaceContainer038": {
    "value": "var(--colors-color-palette-surface-container038)",
    "variable": "var(--colors-color-palette-surface-container038)"
  },
  "colors.colorPalette.surfaceContainer090": {
    "value": "var(--colors-color-palette-surface-container090)",
    "variable": "var(--colors-color-palette-surface-container090)"
  },
  "colors.colorPalette.surfaceContainerLow": {
    "value": "var(--colors-color-palette-surface-container-low)",
    "variable": "var(--colors-color-palette-surface-container-low)"
  },
  "colors.colorPalette.surfaceContainerLow008": {
    "value": "var(--colors-color-palette-surface-container-low008)",
    "variable": "var(--colors-color-palette-surface-container-low008)"
  },
  "colors.colorPalette.surfaceContainerLow012": {
    "value": "var(--colors-color-palette-surface-container-low012)",
    "variable": "var(--colors-color-palette-surface-container-low012)"
  },
  "colors.colorPalette.surfaceContainerLow038": {
    "value": "var(--colors-color-palette-surface-container-low038)",
    "variable": "var(--colors-color-palette-surface-container-low038)"
  },
  "colors.colorPalette.surfaceContainerLow090": {
    "value": "var(--colors-color-palette-surface-container-low090)",
    "variable": "var(--colors-color-palette-surface-container-low090)"
  },
  "colors.colorPalette.surfaceContainerLowest": {
    "value": "var(--colors-color-palette-surface-container-lowest)",
    "variable": "var(--colors-color-palette-surface-container-lowest)"
  },
  "colors.colorPalette.surfaceContainerLowest008": {
    "value": "var(--colors-color-palette-surface-container-lowest008)",
    "variable": "var(--colors-color-palette-surface-container-lowest008)"
  },
  "colors.colorPalette.surfaceContainerLowest012": {
    "value": "var(--colors-color-palette-surface-container-lowest012)",
    "variable": "var(--colors-color-palette-surface-container-lowest012)"
  },
  "colors.colorPalette.surfaceContainerLowest038": {
    "value": "var(--colors-color-palette-surface-container-lowest038)",
    "variable": "var(--colors-color-palette-surface-container-lowest038)"
  },
  "colors.colorPalette.surfaceContainerLowest090": {
    "value": "var(--colors-color-palette-surface-container-lowest090)",
    "variable": "var(--colors-color-palette-surface-container-lowest090)"
  },
  "colors.colorPalette.surfaceBright": {
    "value": "var(--colors-color-palette-surface-bright)",
    "variable": "var(--colors-color-palette-surface-bright)"
  },
  "colors.colorPalette.surfaceBright008": {
    "value": "var(--colors-color-palette-surface-bright008)",
    "variable": "var(--colors-color-palette-surface-bright008)"
  },
  "colors.colorPalette.surfaceBright012": {
    "value": "var(--colors-color-palette-surface-bright012)",
    "variable": "var(--colors-color-palette-surface-bright012)"
  },
  "colors.colorPalette.surfaceBright038": {
    "value": "var(--colors-color-palette-surface-bright038)",
    "variable": "var(--colors-color-palette-surface-bright038)"
  },
  "colors.colorPalette.surfaceBright090": {
    "value": "var(--colors-color-palette-surface-bright090)",
    "variable": "var(--colors-color-palette-surface-bright090)"
  },
  "colors.colorPalette.surfaceDim": {
    "value": "var(--colors-color-palette-surface-dim)",
    "variable": "var(--colors-color-palette-surface-dim)"
  },
  "colors.colorPalette.surfaceDim008": {
    "value": "var(--colors-color-palette-surface-dim008)",
    "variable": "var(--colors-color-palette-surface-dim008)"
  },
  "colors.colorPalette.surfaceDim012": {
    "value": "var(--colors-color-palette-surface-dim012)",
    "variable": "var(--colors-color-palette-surface-dim012)"
  },
  "colors.colorPalette.surfaceDim038": {
    "value": "var(--colors-color-palette-surface-dim038)",
    "variable": "var(--colors-color-palette-surface-dim038)"
  },
  "colors.colorPalette.surfaceDim090": {
    "value": "var(--colors-color-palette-surface-dim090)",
    "variable": "var(--colors-color-palette-surface-dim090)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar