import { SpriteIcon, SpriteIconProps } from 'ui/material/components/sprites';

import { SystemStyleObject } from '../../styled-system/types';
import { css } from '../../styled-system/css';

type Css = { css?: SystemStyleObject };

export function Icon({ css: cssObject = {}, ...props }: Omit<SpriteIconProps, 'path'> & Css) {
  return <SpriteIcon className={css(cssObject)} path='/sprites/spritesheet.svg' {...props} />;
}
