import { CONSTANTS } from '../../constants';
import quizJson from '../_components/quiz/quiz.json';
import { z } from 'zod';

// Workflow
export enum WorkflowStatus {
  Unscheduled = 'unscheduled',
  Claimed = 'claimed',
  Scheduled = 'scheduled',
  Called = 'called',
  CallFailed = 'callFailed',
  Approved = 'approved',
  Denied = 'denied',
  ProviderPaid = 'providerPaid',
}

export const workflowSchema = z.object({
  id: z.string(),
  callTime: z.string().optional(),
  status: z.nativeEnum(WorkflowStatus),
  createdAt: z.string(),
  updatedAt: z.string(),
  createdBy: z.string(),
  updatedBy: z.string(),
});
export const workflowsSchema = z.record(z.string(), workflowSchema);
export type Workflow = z.infer<typeof workflowSchema>;
export type Workflows = z.infer<typeof workflowsSchema>;

// Providers
const REAL_STATES = quizJson.find((q) => q.key === 'state')?.answers?.map((a) => a.toLowerCase()) ?? [];

export const STATES = [...REAL_STATES, 'testizona'];

export const providerSchema = z.object({
  email: z.string(),
  displayName: z.string().nullish(),
  active: z.boolean(),
  lastAssignedAt: z.string().default(''),
  assignedCount: z.number(),
  states: z.array(z.string()),
});

export type Provider = z.infer<typeof providerSchema>;
export type Providers = Provider[];

// Quiz
export const answerSchema = z.object({
  question: z.string(),
  text: z.string(),
  points: z.number(),
  key: z.string().nullable().default(null),
});
export const quizStateSchema = z.object({
  id: z.string(),
  userId: z.string().optional(),
  answers: z.array(answerSchema),
  isValid: z.boolean(),
  points: z.number().default(0),

  approved: z.string().optional(),
  created: z.string().default(new Date().toISOString()),
  paid: z.string().optional(),
  submitted: z.string().optional(),
  assignedTo: z.string().default(''),
  assignedAt: z.string().default(''),
  claimedBy: z.string().default(''),
  claimedAt: z.string().default(''),
  approvedBy: z.string().default(''),
  approvedAt: z.string().default(''),
  providerPaid: z.boolean().default(false),
  state: z.string().optional(),
  promotionCode: z.string().nullish().default(null),

  saved: z.string().optional(),
  savedToUser: z.string().optional(),
});

export type QuizState = z.infer<typeof quizStateSchema>;

// User
export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  code: z.string().optional(),
  updatedAt: z.string(),
});
export type User = z.infer<typeof userSchema>;

// Agreement
export const agreementSchema = z.object({
  type: z.enum(['affiliate', 'provider']),
  markdown: z.string().optional(),
  signedAt: z.string(),
  createdAt: z.string(),
});
export type Agreement = z.infer<typeof agreementSchema>;

// Stripe
export enum StripeWebhookType {
  CheckoutSession = 'checkout.session',
  CheckoutSessionCompleted = 'checkout.session.completed',
  Account = 'account',
  Charge = 'charge',
  Refund = 'refund',
}

// Charge
export const chargeSchema = z.object({
  id: z.string(),
  object: z.literal(StripeWebhookType.Charge),
  amount: z.number(),
  amount_captured: z.number(),
  amount_refunded: z.number(),
  balance_transaction: z.string(),
  created: z.number(),
  payment_intent: z.string(),
  status: z.string(),
});
export type Charge = z.infer<typeof chargeSchema>;

// Refund
export const refundSchema = z.object({
  id: z.string(),
  object: z.literal(StripeWebhookType.Refund),
  amount: z.number(),
  charge: z.string(),
  balance_transaction: z.string(),
  created: z.number(),
  reason: z.string().nullish(),
  payment_intent: z.string(),
  status: z.string(),
});

// Checkout Session
export const checkoutSessionSchema = z
  .object({
    id: z.string().nullish(),
    object: z.literal(StripeWebhookType.CheckoutSession),
    client_reference_id: z
      .string()
      .nullish()
      .transform((val) => (val ? val : 'anonymous')),
    amount_total: z.number().optional(),
    amount_subtotal: z.number().optional(),
    payment_status: z.enum(['paid', 'unpaid', 'no_payment_required']),
    payment_intent: z.string().nullish(),
    customer_details: z
      .object({
        email: z.string(),
        name: z.string().nullish(),
        phone: z.string().nullish(),
      })
      .nullish(),
    refunds: z.record(z.string(), chargeSchema).nullish(),
    created: z.number().nullish(),
  })
  .passthrough();

export const checkoutSessionCompletedSchema = checkoutSessionSchema.merge(
  z.object({
    object: z.literal(StripeWebhookType.CheckoutSessionCompleted),
  })
);

export type CheckoutSession = z.infer<typeof checkoutSessionSchema>;
export type CheckoutSessionCompleted = z.infer<typeof checkoutSessionCompletedSchema>;

// Stripe Account
export const addressSchema = z.object({
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  line1: z.string().optional().nullable(),
  line2: z.string().optional().nullable(),
  postal_code: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
});

const businessProfileSchema = z.object({
  mcc: z.string().nullish(),
  name: z.string().nullish(),
  product_description: z.string().nullish(),
  support_address: addressSchema.nullish(),
  support_email: z.string().nullish(),
  support_phone: z.string().nullish(),
  support_url: z.string().nullish(),
  url: z.string().nullish(),
});

const capabilitiesSchema = z
  .object({
    card_payments: z.enum(['active', 'inactive', 'pending']).nullish(),
    transfers: z.enum(['active', 'inactive', 'pending']).nullish(),
  })
  .passthrough();

const externalAccountsSchema = z.object({
  object: z.string(),
  data: z.array(z.unknown()),
  has_more: z.boolean(),
  total_count: z.number(),
  url: z.string(),
});

const requirementsSchema = z
  .object({
    alternatives: z.array(z.unknown()),
    current_deadline: z.number().nullable(),
    currently_due: z.array(z.string()),
    disabled_reason: z.string().nullable(),
    errors: z.array(z.object({ code: z.string(), reason: z.string(), requirement: z.string() })),
    eventually_due: z.array(z.string()),
    past_due: z.array(z.string()),
    pending_verification: z.array(z.unknown()),
  })
  .passthrough();

const settingsSchema = z
  .object({
    card_payments: z.object({
      decline_on: z
        .object({
          avs_failure: z.boolean(),
          cvc_failure: z.boolean(),
        })
        .nullish(),
    }),
    dashboard: z.object({
      display_name: z.string().nullish(),
      timezone: z.string(),
    }),
    payments: z.object({
      statement_descriptor: z.string().nullish(),
    }),
    payouts: z
      .object({
        debit_negative_balances: z.boolean(),
        schedule: z.object({
          delay_days: z.number(),
          interval: z.string(),
          monthly_anchor: z.number().nullish(),
          weekly_anchor: z.string().nullish(),
        }),
        statement_descriptor: z.string().nullish(),
      })
      .nullish(),
  })
  .passthrough();

const tosAcceptanceSchema = z.object({
  date: z.number().nullish(),
  ip: z.string().nullish(),
  service_agreement: z.string().nullish(),
  user_agent: z.string().nullish(),
});

export const stripeAccountSchema = z.object({
  id: z.string(),
  object: z.literal(StripeWebhookType.Account),
  business_profile: businessProfileSchema,
  business_type: z.string().nullish(),
  capabilities: capabilitiesSchema,
  charges_enabled: z.boolean(),
  country: z.string(),
  created: z.number().nullish(),
  default_currency: z.string(),
  details_submitted: z.boolean(),
  email: z.string().nullish(),
  external_accounts: externalAccountsSchema.nullish(),
  future_requirements: requirementsSchema,
  metadata: z
    .object({
      userId: z.string().default('anonymous'),
      affiliatePayoutPercentage: z.string().default(CONSTANTS.ENV.AFFILIATE_PAYOUT_PERCENTAGE),
      providerPayoutCents: z.string().default(CONSTANTS.ENV.PROVIDER_PAYOUT_CENTS),
    })
    .passthrough()
    .default({
      userId: 'anonymous',
      affiliatePayoutPercentage: CONSTANTS.ENV.AFFILIATE_PAYOUT_PERCENTAGE,
      providerPayoutCents: CONSTANTS.ENV.PROVIDER_PAYOUT_CENTS,
    }),
  payouts_enabled: z.boolean(),
  requirements: requirementsSchema,
  settings: settingsSchema,
  tos_acceptance: tosAcceptanceSchema.nullish(),
  type: z.string(),
});
export type StripeAccount = z.infer<typeof stripeAccountSchema>;

// Payouts
export const calculatedTransferMetadataSchema = z.object({
  id: z.string(),
  type: z.enum(['provider', 'affiliate']),
  checkoutSessionId: z.string(),
  userId: z.string(),
  quizId: z.string(),
  partnerUserId: z.string(),
  partnerEmail: z.string(),
  checkoutSession: checkoutSessionSchema,
  refunds: z.array(refundSchema),
  amount: z.number(),
  paid: z.boolean().default(false),
  paidAt: z.string().optional(),
  createdAt: z.string(),
});
export type CalculatedTransferMetadata = z.infer<typeof calculatedTransferMetadataSchema>;

export const calculatedPayoutSchema = z.object({
  userId: z.string(),
  stripeAccountId: z.string(),
  amountCents: z.number(),
  transactionsCount: z.number(),
  transactionsCents: z.number(),
  type: z.enum(['provider', 'affiliate']),
  rate: z.number(),
});

// Transfer
export const transferSchema = z
  .object({
    amount: z.number(),
    amount_reversed: z.number(),
    balance_transaction: z.string(),
    created: z.number(),
    currency: z.string(),
    description: z.string().nullish(),
    destination: z.string(),
    destination_payment: z.string(),
    id: z.string(),
    livemode: z.boolean(),
    metadata: z.any(),
    object: z.literal('transfer'),
    reversals: z.object({
      data: z.object({
        has_more: z.boolean(),
        object: z.literal('list'),
        total_count: z.number(),
        url: z.string(),
      }),
    }),
    reversed: z.boolean(),
    source_transaction: z.string(),
    source_type: z.string(),
    transfer_group: z.string().nullish(),
  })
  .passthrough();
export type Transfer = z.infer<typeof transferSchema>;
export type Transfers = Transfer[];

// Stripe Objects
export const stripeDataSchema = z.discriminatedUnion('object', [
  chargeSchema,
  checkoutSessionSchema,
  checkoutSessionCompletedSchema,
  stripeAccountSchema,
]);
