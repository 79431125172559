import { useEffect, useState } from 'react';

import { token } from '../styled-system/tokens';

const TOKENS = {
  sm: token('sizes.breakpoint-sm', '640px'),
  md: token('sizes.breakpoint-md', '768px'),
  lg: token('sizes.breakpoint-lg', '1024px'),
  xl: token('sizes.breakpoint-xl', '1280px'),
  '2xl': token('sizes.breakpoint-2xl', '1536px'),
};

type Breakpoint = keyof typeof TOKENS;
type Breakpoints = Record<Breakpoint, boolean>;
type Active = Breakpoint | 'mobile';

const defaultBreakpoints: Breakpoints = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
  '2xl': false,
};
const descendingBreakpoints: Breakpoint[] = ['2xl', 'xl', 'lg', 'md', 'sm'];

export function useBreakpoints() {
  const [active, setActive] = useState<Active>('mobile');
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(defaultBreakpoints);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const unlisteners = Object.entries(TOKENS).map(([key, value]) => {
      const mediaQuery = window.matchMedia(`(min-width: ${value})`);
      function handleChange(e: Pick<MediaQueryListEvent, 'matches'>) {
        setBreakpoints((breakpoints) => ({ ...breakpoints, [key]: e.matches }));
      }

      handleChange(mediaQuery);
      mediaQuery.addEventListener('change', handleChange);

      return () => mediaQuery.removeEventListener('change', handleChange);
    });

    setInitialized(true);

    return () => {
      unlisteners.forEach((fn) => fn());
    };
  }, []);

  useEffect(() => {
    const active = descendingBreakpoints.find((key) => breakpoints[key]);

    setActive(active || 'mobile');
  }, [breakpoints]);

  return { active, breakpoints, initialized };
}
