import ASSETS from './assets.json';

export const CONSTANTS = {
  ASSETS,
  EMAIL: {
    SUPPORT: 'contact@therapyanimalhub.com',
    WEB_ADMIN: 'chris@chrisesplin.com',
  },
  ENV: {
    ADMIN_EMAILS: ['chris@chrisesplin.com', 'sales@therapyanimalhub.com', 'joshl2014@gmail.com', 'wcpringle@gmail.com'],
    FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? '',
    RESEND_API_KEY: process.env.RESEND_API_KEY ?? '',
    STRIPE_PAYMENT_LINK:
      process.env.NEXT_PUBLIC_STRIPE_PAYMENT_LINK ?? 'https://buy.stripe.com/test_14kcNLg4rbSUc1ibII',
    STRIPE_CUSTOMER_PORTAL_LINK:
      process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK ??
      'https://billing.stripe.com/p/login/test_5kA3ci7S703p21aeUU',
    STRIPE_SK: process.env.STRIPE_SK ?? '',
    STRIPE_COUPON_ID: process.env.STRIPE_COUPON_ID ?? '',
    STRIPE_PARTNER_REDIRECT_URL: process.env.STRIPE_PARTNER_REDIRECT_URL ?? '',
    PROVIDER_PAYOUT_CENTS: '2000',
    AFFILIATE_PAYOUT_PERCENTAGE: '.25',
    PAYOUT_CALCULATION_DAY: 1,
    PAYOUT_PAYOUT_DAY: 7,
    POSTHOG_HOST: 'https://app.posthog.com',
    POSTHOG_KEY: 'phc_aM4F2DHZ6BJ2mpdP8Zi2jW4EyanOfFSpp8gtF3ZQr0D',
    HIGHLIGHT_PROJECT_ID: '0dqqwy9d',
  },
};
